import { Button, Flex, Tabs } from 'antd';
import dayjs from 'dayjs';
import { useState } from 'react';

import { useAdminBillingDocumentController_delete } from '@api-client/generated/AdminBillingDocumentController/delete';
import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';
import { Scrollbar } from '@components';
import BillingOneTimeInvoicesItemsList from '@entities/billing/services-list/BillingOneTimeInvoicesItemsList';
import BillingTabDocument from '@entities/billing/tabs/BillingTabDocument';
import { PopupDelete } from '@entities/index';
import { useCurrentCompanyId } from '@hooks';

import BillingCardSkeleton from '../BillingCardSkeleton';
import * as S from './styled';

enum TabKey {
  Details = 'details',
  PaymentDocument = 'paymentDocument',
}

type BillingOneTimeInvoiceCardProps = {
  details?: Schemas.BillingDocument;
  isLoading: boolean;
  onAfterDeleteDocument: VoidFunction;
};

const BillingOneTimeInvoiceCard = (props: BillingOneTimeInvoiceCardProps) => {
  const companyId = useCurrentCompanyId();
  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);

  const { mutate: deleteDocument } = useAdminBillingDocumentController_delete();

  const handleRemoveInvoice = () => {
    if (!props.details) {
      return;
    }

    deleteDocument(
      {
        parameter: {
          companyId,
          id: props.details.id,
        },
      },
      {
        onSuccess: () => {
          setIsOpenPopupConfirm(false);
          props.onAfterDeleteDocument();
        },
      }
    );
  };

  if (!props.details) {
    return (
      <S.Container>
        <BillingCardSkeleton />
      </S.Container>
    );
  }

  return (
    <>
      <S.Container vertical>
        <S.Header align="flex-start" justify="space-between" gap={16}>
          <Flex vertical>
            <S.Title align="center" gap={8}>
              {dayjs(props.details.documentMetadata.issueDate).format(
                'DD.MM.YYYY'
              )}
            </S.Title>

            <S.Details>
              {props.details.documentMetadata.items?.length} items
            </S.Details>
          </Flex>
          <Button
            type="primary"
            size="large"
            icon={<IconTrash />}
            onClick={() => setIsOpenPopupConfirm(true)}
            danger
          />
        </S.Header>
        <Tabs
          size="large"
          items={[
            {
              key: TabKey.Details,
              label: 'Details',
              children: (
                <Scrollbar height="calc(100vh - 365px)" withoutPadding>
                  <BillingOneTimeInvoicesItemsList
                    dataSource={props.details.documentMetadata.items}
                  />
                </Scrollbar>
              ),
            },
            {
              key: TabKey.PaymentDocument,
              label: 'Payment Document',
              children: (
                <Scrollbar height="calc(100vh - 365px)" withoutPadding>
                  <BillingTabDocument documentId={props.details.id} />
                </Scrollbar>
              ),
            },
          ]}
        />
      </S.Container>
      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete the invoice?"
        confirmParams={{
          text: 'Yes, delete',
        }}
        onRemove={handleRemoveInvoice}
        onCancel={() => setIsOpenPopupConfirm(false)}
      />
    </>
  );
};

export default BillingOneTimeInvoiceCard;
