import { Flex, Typography } from 'antd';

import styled from '@emotion/styled';

export const Root = styled(Flex)`
`;

export const Title = styled(Typography.Title, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<{ isActive: boolean }>`
  && {
    color: ${({ isActive, theme }) =>
      isActive ? theme.colors.primary : theme.colors.text500};
  }
`;
