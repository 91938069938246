import { Button, Flex, Modal, type ModalProps, message } from 'antd';
import { useState } from 'react';

import { FileOutlined } from '@ant-design/icons';
import { useAdminBillingDocumentController_create } from '@api-client/generated/AdminBillingDocumentController/create';
import { useCurrentCompanyId } from '@hooks';
import { formatFileSize } from '@utils';

import * as S from './styled';

type ModalBillingUploadDocumentProps = {
  periodId: string;
  onCancel: VoidFunction;
  onAfterCreate: (id: string) => void;
} & ModalProps;

const ModalBillingUploadDocument = ({
  periodId,
  open,
  onCancel,
  onAfterCreate,
  ...rest
}: ModalBillingUploadDocumentProps) => {
  const companyId = useCurrentCompanyId();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { mutate: createDocument } = useAdminBillingDocumentController_create();

  const handleCancel = () => {
    setSelectedFile(null);
    onCancel();
  };

  const handleUploadFile = () => {
    if (!selectedFile) {
      return message.error('You must select a file');
    }

    const formData = new FormData();

    formData.append('file', selectedFile);
    formData.append('id', periodId);

    createDocument(
      {
        parameter: {
          companyId: companyId!,
        },
        // @ts-expect-error-next-line
        requestBody: formData,
      },
      {
        onSuccess: (response) => {
          setSelectedFile(null);
          onAfterCreate(response.id);
        },
      }
    );
  };

  return (
    <Modal
      width={504}
      open={open}
      title="Upload invoice"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Flex gap={32} vertical>
        <Flex gap={16} vertical>
          <S.Dragger
            customRequest={(options) => setSelectedFile(options.file as File)}
            beforeUpload={() => {}}
            multiple={false}
            showUploadList={false}
          >
            <Flex align="center" justify="space-between">
              <S.Label>Drop file here to upload</S.Label>
              <Button type="primary" size="large" ghost>
                Select file
              </Button>
            </Flex>
          </S.Dragger>

          {selectedFile && (
            <S.File align="center" justify="space-between" gap={8}>
              <Flex align="center" gap={6}>
                <FileOutlined />
                {selectedFile.name}
              </Flex>

              {formatFileSize(selectedFile.size)}
            </S.File>
          )}
        </Flex>

        <Flex align="center" justify="flex-end" gap={16}>
          <Button onClick={handleCancel} size="large">
            Cancel
          </Button>

          <Button type="primary" size="large" onClick={handleUploadFile}>
            Upload
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default ModalBillingUploadDocument;
