import { Flex, Space } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconClock, IconLock } from '@assets';

import * as S from './styled';

export type DocumentStatus = Schemas.Document['adminStatus'];
export type BobStatus = Schemas.Document['bobStatus'];

type DocumentExpenseStatusProps = {
  status: DocumentStatus;
  bobStatus?: BobStatus;
}

const DocumentExpenseStatus = ({ status, bobStatus }: DocumentExpenseStatusProps) => {
  const isProcessing = status === 'processing';
  const isBooked = status === 'processed';

  return (
    <S.Status status={status}>
      <Space split={<S.Divider />}>
        <S.StatusName status={status}>
          {isProcessing && (
            <Flex align="center" gap={6}>
              In Work {bobStatus === 'pending' ? '(automatic)' : '(manual)'} <IconClock />
            </Flex>
          )}

          {isBooked && (
            <Flex align="center" gap={6}>
              Booked <IconLock />
            </Flex>
          )}
        </S.StatusName>

        {isBooked && (
          <>
            <S.Details>Book date: TBD</S.Details>
            <S.Details>Time to book: TBD</S.Details>
          </>
        )}
      </Space>
    </S.Status>
  );
};

export default DocumentExpenseStatus;
