export const USA_LINK_TYPES = [
  {
    label: "US Citizenship",
    value: 'citizen',
  },
  {
    label: "Green card",
    value: 'greenCard',
  },
  {
    label: "US Place of birth",
    value: 'born',
  },
  {
    label: "US Resident address",
    value: 'residentAddress',
  },
  {
    label: "US Mailing address",
    value: 'mailingAddress',
  },
  {
    label: "US Telephone number",
    value: 'phone',
  },
  {
    label: "Standing order to transfer funds to a US account",
    value: 'standingOrder',
  },
  {
    label: "Proxyholder with a US address",
    value: 'proxyHolder',
  },
];
