import dayjs from 'dayjs';
import { groupBy, uniqBy } from 'lodash';

import { Schemas } from '@api-client/generated/types';

type Invoice = Schemas.BillingPeriodSummaryResponseDto;

type GroupedInvoice = {
  date: string;
  invoices: Invoice[];
};

export const getGroupedInvoicesByStartDate = (
  invoices: Invoice[]
): GroupedInvoice[] => {
  const grouped = groupBy(invoices, (invoice) =>
    dayjs(invoice.startDate).format('MMMM YYYY')
  );

  return Object.entries(grouped)
    .sort(
      ([dateFrom], [dateTo]) =>
        dayjs(dateFrom, 'MMMM YYYY').valueOf() -
        dayjs(dateTo, 'MMMM YYYY').valueOf()
    )
    .map(([date, items]) => ({
      date,
      invoices: uniqBy(items, 'id'),
    }));
};
