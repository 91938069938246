import { Typography } from 'antd';

import countriesData from '@data/countries.json';

import * as S from './styled';

export const countryOptions = countriesData.map((country) => ({
  key: country.code,
  value: country.code,
  name: country.name,
  label: (
    <S.CountryOption>
      <S.CountryImage src={country.image} alt={country.name} />
      <Typography>{country.name}</Typography>
    </S.CountryOption>
  ),
  image: country.image,
}));
