import { useParams } from 'react-router-dom';

const useCurrentCompanyId = (): string => {
  const { companyId } = useParams<{ companyId: string }>();

  if (!companyId) {
    throw new Error(
      'useCurrentCompany hook may be used only inside /companies/:companyId route'
    );
  }

  return companyId;
};

export default useCurrentCompanyId;
