import {
  Form,
  type FormItemProps,
  Input,
  InputNumber,
  type InputNumberProps,
  type InputProps,
} from 'antd';

import styled from '@emotion/styled';

export const FormItem = styled(Form.Item)<FormItemProps>`
  && {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const InputControl = styled(Input)<InputProps>`
  && {
    .ui-input-group-addon {
      padding-inline: 0;
    }
  }
`;

export const InputNumberControl = styled(InputNumber)<InputNumberProps>`
  && {
    width: 100%;
  }
`;
