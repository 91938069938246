//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBillingDocumentController_findAllOneTimeInvoices,
  Response$AdminBillingDocumentController_findAllOneTimeInvoices$Status$200,
  ResponseContentType$AdminBillingDocumentController_findAllOneTimeInvoices,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminBillingDocumentController_findAllOneTimeInvoices =
  (options: {
    params: Params$AdminBillingDocumentController_findAllOneTimeInvoices['parameter'];
    config?: QueryOptions<
      Response$AdminBillingDocumentController_findAllOneTimeInvoices$Status$200[ResponseContentType$AdminBillingDocumentController_findAllOneTimeInvoices]
    >;
  }) =>
    useApiClient<
      Params$AdminBillingDocumentController_findAllOneTimeInvoices,
      Response$AdminBillingDocumentController_findAllOneTimeInvoices$Status$200[ResponseContentType$AdminBillingDocumentController_findAllOneTimeInvoices]
    >({
      method: 'AdminBillingDocumentController_findAllOneTimeInvoices',
      params: { parameter: options.params },
      config: options.config,
    });
