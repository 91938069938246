import { Button, Form, Input, Modal } from 'antd';
import { useEffect } from 'react';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type CompanyOnboardingDetailsEditProps = {
  isOpen: boolean;
  companyKYC?: Schemas.KYCData;
  onSubmit: (values: Schemas.KYCData) => void;
  onCancel: () => void;
};

const CompanyOnboardingInfoEdit = (
  props: CompanyOnboardingDetailsEditProps
) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(props.companyKYC);
  }, [props.companyKYC, form, props.isOpen]);

  const handleClose = () => {
    form.resetFields();
    props.onCancel();
  };

  const handleSubmit = (values: Schemas.KYCData) => {
    props.onSubmit(values);
    handleClose();
  };

  return (
    <Modal
      open={props.isOpen}
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item
          label="Purpose of istablishment"
          name="purposeOfEstablishment"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Assessment of incoming funds"
          name="assessmentOfIncomingFunds"
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Assessment of outgoing funds"
          name="assessmentOfOutgoingFunds"
        >
          <Input />
        </Form.Item>
        <Form.Item label="Clients of company" name="clientsOfCompany">
          <Input />
        </Form.Item>
        <Form.Item label="Countries involved" name="countriesInvolved">
          <Input />
        </Form.Item>
        <Form.Item label="Long term goals" name="longTermGoals">
          <Input />
        </Form.Item>
        <S.Footer gap={8} justify="end">
          <Button size="large" htmlType="button" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="large" type="primary" htmlType="submit">
            Update
          </Button>
        </S.Footer>
      </Form>
    </Modal>
  );
};

export default CompanyOnboardingInfoEdit;
