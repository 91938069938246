import { MouseEvent, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { IconArrowLeft } from '@assets';

import * as S from './styled';

type GoBackProps = {
  text?: ReactNode;
  onNavigate?: VoidFunction;
};

const GoBack = ({ text, onNavigate }: GoBackProps) => {
  const navigate = useNavigate();

  const handleBack = (e: MouseEvent) => {
    e.preventDefault();

    if (onNavigate) {
      onNavigate();
    } else {
      navigate(-1);
    }
  };

  return (
    <S.Link onClick={handleBack}>
      <IconArrowLeft /> {text || 'Go Back'}
    </S.Link>
  );
};

export default GoBack;
