import { Flex } from 'antd';
import { ReactNode } from 'react';

import { IconCheck, IconWarningTransparent } from '@assets';

import * as S from './styled';

type InfoBlockProps = {
  title: string;
  children: ReactNode;
  status?: 'completed' | 'error';
};

export const InfoBlock = (props: InfoBlockProps) => (
  <S.InfoBlockRoot>
    <Flex align="center" gap={8}>
      <S.InfoBlockTitle>{props.title}</S.InfoBlockTitle>
      {props.status === 'completed' && (
        <S.StepNumber status="completed">
          <IconCheck width={16} height={16} />
        </S.StepNumber>
      )}
      {props.status === 'error' && (
        <S.StepNumber status="errorsFound">
          <IconWarningTransparent width={16} height={16} />
        </S.StepNumber>
      )}
    </Flex>
    {props.children}
  </S.InfoBlockRoot>
);
