import styled from '@emotion/styled';

import { type FlexProps, Flex, Typography, Button } from 'antd';

export const Header = styled(Flex)<FlexProps>`
  border-bottom: 2px solid ${({ theme }) => theme.colors.bgDarkGrey};
  font-size: 28px;
  font-weight: 600;
  padding-bottom: 24px;
`;

export const Title = styled(Typography.Title)`
  && {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 0;
  }
`;

export const Inner = styled.div`
  width: 355px;
`;

export const Details = styled.div`
  flex: 1;
`;

export const AddButton = styled(Button)`
  justify-content: flex-start;
`;
