import { Flex, Space } from 'antd';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminCompanyController_findOneById } from '@api-client/generated/AdminCompanyController/findOneById';
import { IconLogout } from '@assets';
import { useAccount } from '@hooks';

import * as S from './styled';

const Header: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();

  const { data: company } = useAdminCompanyController_findOneById({
    params: {
      id: companyId!,
    },
    config: {
      enabled: !!companyId,
    },
  });

  const { account, clearAccount } = useAccount();

  const handleLogout = () => {
    clearAccount();
    navigate('/login');
  };

  return (
    <S.Header>
      <Flex gap={32}>
        {company ? (
          <Space size={8}>
            <S.Text>You now working with:</S.Text>
            <S.CompanyName>{company?.name}</S.CompanyName>
          </Space>
        ) : (
          <div />
        )}
      </Flex>

      <Flex gap={80}>
        <Space size={8}>
          <S.UserName>{account?.email}</S.UserName>
        </Space>
        <S.Logout onClick={handleLogout}>
          <IconLogout />
        </S.Logout>
      </Flex>
    </S.Header>
  );
};

export default Header;
