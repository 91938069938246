import { Flex, List, Typography } from 'antd';
import _ from 'lodash';



import { Schemas } from '@api-client/generated/types';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities';



import * as S from './styled';


type BillingOneTimeInvoicesItemsListProps = {
  dataSource: Schemas.AdminOneTimeInvoiceDto[];
};

const BillingOneTimeInvoiceItemsList = (
  props: BillingOneTimeInvoicesItemsListProps
) => (
  <S.Container>
    <S.Header align="center" justify="space-between">
      <S.HeaderColumn>Description</S.HeaderColumn>
      <S.HeaderColumn>Price</S.HeaderColumn>
    </S.Header>
    <List
      dataSource={props.dataSource}
      renderItem={(item) => (
        <List.Item>
            <S.FullFlex gap={8} vertical>
              <Flex gap={2} vertical>
                <Typography.Text strong>{item.name}</Typography.Text>
                <S.FullFlex justify="space-between">
                  <Typography.Text>{item.description}</Typography.Text>
                  <Amount
                    currencyCode={DEFAULT_CURRENCY_CODE}
                    amount={item.totalPrice}
                    withoutType
                  />
                </S.FullFlex>
              </Flex>
            </S.FullFlex>
        </List.Item>
      )}
    />
    <S.Total align="center" justify="flex-end" gap={16}>
      <S.TotalTitle>Total</S.TotalTitle>

      <Amount
        currencyCode={DEFAULT_CURRENCY_CODE}
        amount={_.sumBy(props.dataSource, 'totalPrice')}
        withoutType
      />
    </S.Total>
  </S.Container>
);

export default BillingOneTimeInvoiceItemsList;
