import { Collapse, CollapseProps, Typography } from 'antd';
import { useMemo, useState } from 'react';

import { Loader } from '@components';
import {
  CompanyDetailsEdit,
  CompanyDetailsFeatures,
  CompanyDetailsFeaturesEdit,
  CompanyDetailsInfo,
  CompanyDetailsPerson,
  CompanyDetailsPersonEdit,
  CompanyDetailsServiceInfo,
  CompanyDetailsServiceInfoEdit,
} from '@entities';
import { useCurrentCompany } from '@hooks';
import * as S from '@pages/Onboarding/styled';

const { Text } = Typography;

const CompanyInfo = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    refetch: refetchCompany,
  } = useCurrentCompany();

  const [modal, setModal] = useState<
    'details' | 'contact' | 'features' | 'service' | null
  >(null);

  const handleAfterEdit = () => {
    setModal(null);
    refetchCompany();
  };

  const handleCloseModal = () => {
    setModal(null);
  };

  const sections: CollapseProps['items'] = useMemo(
    () =>
      company && [
        {
          key: 'details',
          label: <Text strong>Company details</Text>,
          children: (
            <CompanyDetailsInfo
              name={company.name}
              referenceId={company.referenceId}
              details={company.details}
              onEdit={() => setModal('details')}
            />
          ),
        },
        {
          key: 'contact',
          label: <Text strong>Contact person</Text>,
          children: (
            <CompanyDetailsPerson
              details={company.details?.contactPerson}
              onEdit={() => setModal('contact')}
            />
          ),
        },
        {
          key: 'service',
          label: <Text strong>Service</Text>,
          children: (
            <CompanyDetailsServiceInfo
              company={company}
              onEdit={() => setModal('service')}
            />
          ),
        },
        {
          key: 'features',
          label: <Text strong>Features</Text>,
          children: (
            <CompanyDetailsFeatures
              details={company.featuresStatus}
              onEdit={() => setModal('features')}
            />
          ),
        },
      ],
    [company]
  );

  return (
    <>
      <S.Content gap={16} vertical>
        {isFetchingCompany ? (
          <Loader />
        ) : company ? (
          <Collapse
            items={sections}
            defaultActiveKey={[
              'details',
              'contact',
              'service',
              'incorporation',
            ]}
          />
        ) : null}
      </S.Content>
      {company && (
        <>
          <CompanyDetailsEdit
            company={company}
            open={modal === 'details'}
            onCancel={handleCloseModal}
            onAfterEdit={handleAfterEdit}
          />

          <CompanyDetailsPersonEdit
            company={company}
            open={modal === 'contact'}
            onCancel={handleCloseModal}
            onAfterEdit={handleAfterEdit}
          />

          <CompanyDetailsFeaturesEdit
            featuresStatus={company.featuresStatus}
            open={modal == 'features'}
            onCancel={handleCloseModal}
            onAfterEdit={handleAfterEdit}
          />

          <CompanyDetailsServiceInfoEdit
            company={company}
            open={modal === 'service'}
            onCancel={handleCloseModal}
            onAfterEdit={handleAfterEdit}
          />
        </>
      )}
    </>
  );
};

export default CompanyInfo;
