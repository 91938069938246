//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBillingPeriodController_approve,
  Response$AdminBillingPeriodController_approve$Status$200,
  ResponseContentType$AdminBillingPeriodController_approve,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminBillingPeriodController_approve = () =>
  useApiClientMutation<
    Params$AdminBillingPeriodController_approve,
    Response$AdminBillingPeriodController_approve$Status$200[ResponseContentType$AdminBillingPeriodController_approve]
  >({ method: 'AdminBillingPeriodController_approve' });
