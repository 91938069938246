import { FC, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import {
  type ModalProps,
  Upload,
  Button,
  Flex,
  Form,
  Modal,
  DatePicker,
} from 'antd';
import { useCurrentCompanyId } from '@hooks';
import * as S from './styled';

import { useAdminPayslipDocumentController_create  } from '@api-client/generated/AdminPayslipDocumentController/create'

import { Schemas } from '@api-client/generated/types';

type PayslipsModalUploadProps = {
  onCancel: () => void;
  onAfterUpload: () => void;
} & ModalProps;

const PayslipsModalUpload: FC<PayslipsModalUploadProps> = ({
  open,
  onCancel,
  onAfterUpload,
  ...rest
}) => {
  const companyId = useCurrentCompanyId();

  const [form] = Form.useForm();

  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const { mutate: createDocument } = useAdminPayslipDocumentController_create();

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      const firstDayOfMonth = date.startOf('month');

      form.setFieldsValue({ issueDate: firstDayOfMonth });
    }
  };

  const handleSubmit = (values: Schemas.AdminCreatePayslipDocumentDto) => {
    if (selectedFile && companyId) {
      const formData = new FormData();

      formData.append('file', selectedFile);
      formData.append('issueDate', dayjs(values.issueDate).format());

      createDocument(
        {
          parameter: { companyId },
          requestBody:
            formData as unknown as Schemas.AdminCreatePayslipDocumentDto,
        },
        {
          onSuccess: () => {
            onAfterUpload();
            handleCancel();
          },
        }
      );
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    form.resetFields();
    onCancel();
  };

  return (
    <Modal
      width={504}
      open={open}
      title="Upload document"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <S.Inner gap={16} vertical>
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
          requiredMark={false}
        >
          <S.Wrapper>
            <Form.Item
              label="Issue date"
              name="issueDate"
              rules={[
                { required: true, message: 'Please select an issue date' },
              ]}
            >
              <DatePicker
                picker="month"
                placeholder="DD.MM.YYYY"
                format="DD.MM.YYYY"
                size="large"
                onChange={handleDateChange}
              />
            </Form.Item>
          </S.Wrapper>

          <Flex gap={16} vertical>
            <Upload
              customRequest={({ file }) =>
                file instanceof File && setSelectedFile(file)
              }
              beforeUpload={() => {}}
              showUploadList={false}
            >
              <Button type="primary" size="large" ghost>
                Upload document
              </Button>
            </Upload>

            <Form.Item noStyle>
              <Flex justify="flex-end" gap={12}>
                <Button onClick={handleCancel} size="large">
                  Cancel
                </Button>

                <Button type="primary" htmlType="submit" size="large">
                  Upload
                </Button>
              </Flex>
            </Form.Item>
          </Flex>
        </Form>
      </S.Inner>
    </Modal>
  );
};

export default PayslipsModalUpload;
