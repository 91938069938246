import styled from '@emotion/styled';

export const Header = styled.div`
  position: sticky;
  top: 70px;
  z-index: 3;
  background: ${({ theme }) => theme.colors.bgLightBlue200};
  padding: 25px 50px 20px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.strokeDarkGrey};
`;

export const Divider = styled.div`
  width: 2px;
  height: 24px;
  background-color: ${({ theme }) => theme.colors.strokeDarkGrey};
  margin: 0 16px;
`;
