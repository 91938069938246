import styled from '@emotion/styled';

import { Layout, type LayoutProps } from 'antd';

export const Container = styled(Layout)<LayoutProps>`
  min-height: 100vh;
  padding-left: 105px;
`;

export const Content = styled(Layout.Content)<LayoutProps>`
  padding: 32px;
`;
