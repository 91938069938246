import {
  Col,
  type ColProps,
  DatePicker,
  type DatePickerProps,
  type FormItemProps,
  Input,
  type InputNumberProps,
  type InputProps,
} from 'antd';
import { type TextAreaProps } from 'rc-textarea/lib/interface';

import { Select, type SelectProps } from '@components';

import * as S from './styled';

type CommonProps = {
  form: FormItemProps;
};

type WrapperProps = {
  span?: ColProps['span'];
};

type InputControlProps = CommonProps & {
  type: 'input';
  control?: InputProps;
};

type TextAreaControlProps = CommonProps & {
  type: 'textarea';
  control?: TextAreaProps;
};

type InputNumberControlProps = CommonProps & {
  type: 'input-number';
  control?: InputNumberProps;
};

type SelectControlProps = CommonProps & {
  type: 'select';
  control?: SelectProps;
};

type DatePickerControlProps = CommonProps & {
  type: 'date-picker';
  control?: DatePickerProps;
};

type FormControlProps =
  | InputControlProps
  | InputNumberControlProps
  | TextAreaControlProps
  | SelectControlProps
  | DatePickerControlProps;

const FormControl = ({ type, form, control }: FormControlProps) => (
  <S.FormItem {...form}>
    {type === 'input' && <S.InputControl size="large" {...control} />}

    {type === 'input-number' && (
      <S.InputNumberControl size="large" {...control} />
    )}

    {type === 'textarea' && <Input.TextArea size="large" {...control} />}

    {type === 'select' && <Select size="large" showSearch {...control} />}

    {type === 'date-picker' && (
      <DatePicker size="large" style={{ width: '100%' }} {...control} />
    )}
  </S.FormItem>
);

const DocumentExpenseWrapperFormControl = ({
  span,
  ...props
}: FormControlProps & WrapperProps) => {
  if (span) {
    return (
      <Col span={span}>
        <FormControl {...props} />
      </Col>
    );
  }

  return <FormControl {...props} />;
};

export default DocumentExpenseWrapperFormControl;
