import { Flex, Modal, Popover, Upload, message } from 'antd';
import type { UploadRequestOption } from 'rc-upload/lib/interface';
import { FC, ReactNode, useEffect, useRef, useState } from 'react';

import { useAdminCompanyFileController_create } from '@api-client/generated/AdminCompanyFileController/create';
import { useAdminCompanyFileController_delete } from '@api-client/generated/AdminCompanyFileController/delete';
import { useAdminCompanyFileController_findAll } from '@api-client/generated/AdminCompanyFileController/findAll';
import { useAdminCompanyFileController_updateOneById } from '@api-client/generated/AdminCompanyFileController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconEdit, IconMoreVertical, IconTrash, IconUpload } from '@assets';
import { Card, Loader } from '@components';
import {
  EmptyDocumentPreview,
  FileViewer,
  SearchControl,
  UploadFileItem,
} from '@entities';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';
import { useFileUpload } from './useFileUpload';

type LegalContentProps = {
  title?: ReactNode;
  externalCompanyId?: string;
};

const LegalContent: FC<LegalContentProps> = ({ externalCompanyId, title }) => {
  const accountCompanyId = useCurrentCompanyId();

  const companyId = externalCompanyId || accountCompanyId;

  const { mutate: createFile, isPending: createFileLoading } =
    useAdminCompanyFileController_create();
  const { mutate: updateFile, isPending: updateFileLoading } =
    useAdminCompanyFileController_updateOneById();
  const { mutate: deleteFile, isPending: deleteFileLoading } =
    useAdminCompanyFileController_delete();

  const [selectedFile, setSelectedFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const { uploadStatus } = useFileUpload(companyId!);
  const [files, setFiles] = useState<Schemas.CompanyFile[]>([]);
  const [changingFileId, setChangingFileId] = useState('');
  const [isActionsOpen, setIsActionsOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const ref = useRef(null);

  const { data: queryFiles, isPending: getFilesLoading } =
    useAdminCompanyFileController_findAll({
      params: {
        companyId: companyId!,
        type: 'legal',
        personId: companyId!,
      },
    });

  useEffect(
    () => {
      if (queryFiles?.length) {
        setFiles(queryFiles.sort((a, b) => a.name.localeCompare(b.name)));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getFilesLoading]
  );

  useEffect(
    () => {
      if (files.length && !selectedFile && !searchValue) {
        setSelectedFile(files[0]);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [files, selectedFile]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (changingFileId && changingFileId !== selectedFile?.id) {
      setChangingFileId('');
    }
  });

  const searchFiles = () =>
    files.filter((file) =>
      file.name.toLowerCase().includes(searchValue.toLowerCase())
    );

  const isTypePDF = selectedFile?.mimeType?.includes('pdf');

  const handleUploadFile = (options: UploadRequestOption) => {
    const formData = new FormData();
    if (typeof options.file === 'object') {
      formData.append('file', options.file);
      if ('name' in options.file) {
        formData.append('name', options.file.name);
      }
    }
    formData.append('type', 'legal');
    formData.append('personId', companyId!);

    createFile(
      {
        parameter: {
          companyId: companyId!,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        requestBody: formData as any,
      },
      {
        onSuccess: (res) => {
          setFiles(
            files?.length
              ? [...files, res].sort((a, b) => a.name.localeCompare(b.name))
              : [res]
          );
          setSelectedFile(res);
        },
      }
    );
  };

  const handleUpdateFile = (name: string) => {
    if (selectedFile) {
      updateFile(
        {
          parameter: {
            companyId: companyId!,
            id: selectedFile.id,
          },
          requestBody: {
            name,
          },
        },
        {
          onSuccess: (res) => {
            setChangingFileId('');
            setFiles((prev) =>
              prev
                .map((el) => (el.id === res.id ? res : el))
                .sort((a, b) => a.name.localeCompare(b.name))
            );
            message.success(`File ${name} was saved`);
          },
        }
      );
    }
  };

  const handleRemoveFile = () => {
    if (selectedFile) {
      deleteFile(
        {
          parameter: {
            companyId: companyId!,
            id: selectedFile.id,
          },
        },
        {
          onSuccess: (res) => {
            setFiles((prev) => prev.filter((el) => el.id !== res.id));
            setSelectedFile(null);
            setIsRemoveModalOpen(false);
            message.success('The document has been deleted');
          },
        }
      );
    }
  };

  const handleChangeVisible = (
    value: boolean,
    companyFile: Schemas.CompanyFile
  ) => {
    setIsActionsOpen(value);
    setSelectedFile(companyFile);
  };

  const handleEditFile = (id: string) => {
    setChangingFileId(id);
    setIsActionsOpen(false);
  };

  const handleOpenRemoveModal = () => {
    setIsRemoveModalOpen(true);
    setIsActionsOpen(false);
  };

  const actionsLoading =
    createFileLoading || updateFileLoading || deleteFileLoading;

  return (
    <Flex gap={14} ref={ref} vertical>
      {title}

      <Flex gap={28} vertical>
        <Flex justify="space-between" align="center">
          <SearchControl
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
          <Upload
            customRequest={handleUploadFile}
            beforeUpload={() => {}}
            showUploadList={false}
          >
            <S.UploadButton
              type="default"
              size="small"
              loading={createFileLoading}
              disabled={createFileLoading}
              icon={<IconUpload />}
            >
              Upload document
            </S.UploadButton>
          </Upload>
        </Flex>

        <Flex gap={30}>
          {searchFiles()?.length ? (
            <Flex flex={1} gap={12} vertical>
              {searchFiles()?.map((companyFile) => (
                <UploadFileItem
                  key={companyFile?.id}
                  isFileChanging={changingFileId === companyFile.id}
                  onFileNameSave={handleUpdateFile}
                  url={companyFile.url!}
                  name={companyFile.name}
                  loading={uploadStatus.isLoading}
                  onSelect={() => setSelectedFile(companyFile)}
                  isSelected={companyFile?.id === selectedFile?.id}
                  additionalIcon={
                    companyFile?.id === selectedFile?.id &&
                    updateFileLoading ? (
                      <Loader />
                    ) : (
                      <Popover
                        visible={
                          isActionsOpen && companyFile?.id === selectedFile?.id
                        }
                        onVisibleChange={(value) =>
                          handleChangeVisible(value, companyFile)
                        }
                        trigger="click"
                        placement="bottomRight"
                        arrow={false}
                        overlayClassName="ui-popover-legal-actions"
                        title={
                          <S.Actions>
                            <S.Edit
                              onClick={() => handleEditFile(companyFile?.id)}
                            >
                              <IconEdit />
                              <span>Change name</span>
                            </S.Edit>
                            <S.Remove onClick={handleOpenRemoveModal}>
                              <IconTrash />
                              <span>Delete</span>
                            </S.Remove>
                          </S.Actions>
                        }
                      >
                        <S.MoreIcon>
                          <IconMoreVertical />
                        </S.MoreIcon>
                      </Popover>
                    )
                  }
                />
              ))}
            </Flex>
          ) : (
            <Flex flex={1}>
              <S.EmptyList align="center" justify="center" gap={32} vertical>
                {getFilesLoading ? (
                  <Loader />
                ) : (
                  <>
                    <Flex gap={7} justify="center" align="center" vertical>
                      <span>There are no company documents yet</span>
                      <span>
                        You need to add your documents to display them here
                      </span>
                    </Flex>
                    <Upload
                      customRequest={handleUploadFile}
                      beforeUpload={() => {}}
                      showUploadList={false}
                    >
                      <S.UploadButton
                        type="default"
                        size="small"
                        loading={createFileLoading}
                        disabled={createFileLoading}
                        icon={<IconUpload />}
                      >
                        Upload document
                      </S.UploadButton>
                    </Upload>
                  </>
                )}
              </S.EmptyList>
            </Flex>
          )}
          <S.EmptyPreview>
            <Card title="Preview" minHeight={554}>
              {!!selectedFile && isTypePDF ? (
                <FileViewer
                  selectedFile={selectedFile}
                  loading={actionsLoading}
                  width="437px"
                  height="622px"
                />
              ) : (
                <>
                  {actionsLoading ? (
                    <Loader />
                  ) : (
                    <EmptyDocumentPreview
                      unsupported={!!selectedFile && !isTypePDF}
                    />
                  )}
                </>
              )}
            </Card>
          </S.EmptyPreview>
        </Flex>
      </Flex>
      <Modal open={isRemoveModalOpen} closeIcon={null} footer={null} centered>
        <Flex gap={24} vertical>
          <Flex gap={9} vertical>
            <S.ModalTitle>
              Are you sure you want to delete this document?
            </S.ModalTitle>
            <span>Once deleted, you will not be able to restore it</span>
          </Flex>

          <Flex justify="flex-end" gap={12}>
            <S.ModalButton
              isCancel
              type="default"
              onClick={() => setIsRemoveModalOpen(false)}
            >
              Cancel
            </S.ModalButton>
            <S.ModalButton
              type="primary"
              loading={deleteFileLoading}
              disabled={deleteFileLoading}
              onClick={handleRemoveFile}
            >
              Delete
            </S.ModalButton>
          </Flex>
        </Flex>
      </Modal>
    </Flex>
  );
};

export default LegalContent;
