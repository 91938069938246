import { Button, Flex, Form, List, Modal, Select } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import type { PartialDeep } from 'type-fest';

import { useAdminIncorporationController_updateOneById } from '@api-client/generated/AdminIncorporationController/updateOneById';
import { useAdminOnfidoDataController_create } from '@api-client/generated/AdminOnfidoDataController/create';
import { Schemas } from '@api-client/generated/types';
import { IconLegal, IconUser, IconWarningTransparent } from '@assets';
import { Loader } from '@components';
import { countries } from '@constants';
import { IncorporationKYCEditModal, PersonFileModal } from '@entities';
import { InfoBlock } from '@entities/IncorporationKYC/InfoBlock';
import { useCurrentCompanyId } from '@hooks';

import { InfoRow } from './InfoRow';
import * as S from './styled';

type IncorporationKYCProps = {
  incorporation: IncorporationWithDto;
  people: Schemas.Person[];
  onUpdate: () => void;
};

type Person = Schemas.Person;
type CompanyFile = Schemas.CompanyFile;
type OnfidoData = Schemas.OnfidoData;

type Incorporation = PartialDeep<Schemas.Incorporation>;
type IncorporationDto = PartialDeep<Schemas.AdminIncorporationDto>;

type IncorporationWithDto = Incorporation | IncorporationDto;

const IncorporationKYC: React.FC<IncorporationKYCProps> = ({
  incorporation,
  people,
  onUpdate,
}) => {
  const companyId = useCurrentCompanyId();
  const [activeFile, setActiveFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const [isOpenPersonFileModal, setIsOpenPersonFileModal] = useState(false);

  const [selectedPerson, setSelectedPerson] = useState<Person | null>(null);

  const [modal, contextHolder] = Modal.useModal();
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const [onfidoData, setOnfidoData] = useState<OnfidoData | null>(null);

  const { mutate: updateIncorporationById } =
    useAdminIncorporationController_updateOneById();
  const { mutate: getOnfidoData, isPending: loadingOnfido } =
    useAdminOnfidoDataController_create();

  const [isRequestChangesFormVisible, setIsRequestChangesFormVisible] =
    useState(false);

  const closePersonFile = () => {
    setActiveFile(null);
    setIsOpenPersonFileModal(false);
  };

  const selectFile = (file: Schemas.CompanyFile) => {
    setActiveFile(file);
    setIsOpenPersonFileModal(true);
  };

  const getStatusDescription = (file: Schemas.CompanyFile) => {
    if (file.isApproved) {
      return 'Approved';
    }

    if (file.hasError) {
      return 'Has error, waiting for client';
    }

    return 'Needs to be checked';
  };

  const handleSelectPerson = (person: Person) => {
    setSelectedPerson(person);
    setIsRequestChangesFormVisible(false);
  };

  useEffect(() => {
    if (!selectedPerson) {
      return;
    }

    getOnfidoData(
      {
        parameter: {
          companyId: companyId!,
          personId: selectedPerson.id,
        },
      },
      {
        onSuccess: (response) => {
          setOnfidoData(response);
        },
      }
    );
    // eslint-disable-next-line
  }, [selectedPerson]);

  const getPersonRole = (person: Person) => {
    const roles: string[] = [];

    if (person.isShareholder) {
      roles.push('Shareholder');
    }

    if (person.isDirector) {
      roles.push('Manager');
    }

    return roles.join(', ');
  };

  const hasErrorStep = (step: string) => {
    if (step === 'id' && selectedPerson?.kycData?.hasFailedBiometricCheck) {
      return true;
    }

    if (step === 'wealth') {
      const incomeWithFileRequestedButNoFileOrError =
        selectedPerson?.kycData?.incomes?.find((income) => {
          if (income) {
            const step = `wealth_${income.type}`;
            return (
              income.isRequiredConfimation === 'yes' &&
              (!selectedPerson.files[step] ||
                selectedPerson.files[step].length === 0 ||
                selectedPerson.files[step].some(
                  (file: CompanyFile) => file.hasError
                ))
            );
          }
        });

      if (incomeWithFileRequestedButNoFileOrError) {
        return true;
      }
    }

    if (['tin', 'wealth'].includes(step) && selectedPerson?.kycData) {
      /* @ts-expect-error-next-line */
      return selectedPerson.kycData[`${step as StepWithFormErrors}ErrorCode`];
    }
    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const allApprovedOnStep = (step: string) => {
    const errors = (selectedPerson?.files[step] || [])
      .map((file: CompanyFile) => !file.isApproved)
      .filter((status: boolean) => status);

    return errors.length === 0;
  };

  const hasErrorPerson = (person: Person) => {
    const incomeWithFileRequestedButNoFile = person?.kycData?.incomes?.find(
      (income) => {
        if (income) {
          const step = `wealth_${income.type}`;
          return (
            income.isRequiredConfimation === 'yes' &&
            (!person.files[step] || person.files[step].length === 0)
          );
        }
      }
    );

    if (incomeWithFileRequestedButNoFile) {
      return true;
    }

    if (
      person?.kycData?.tinErrorCode ||
      person?.kycData?.wealthErrorCode ||
      person?.kycData?.hasFailedBiometricCheck
    ) {
      return true;
    }

    const errors = Object.keys(person.files)
      .flatMap((key) => person.files[key])
      .map((file: CompanyFile) => file.hasError)
      .filter((status: boolean) => status);

    return !!errors.length;
  };

  const getStepStatus = (step: string) => {
    if (allApprovedOnStep(step)) {
      return 'completed';
    } else if (hasErrorStep(step)) {
      return 'error';
    }

    return undefined;
  };

  const requestDocsForIncome = (incomeType: string) => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () => {
        const currentKycData = selectedPerson!.kycData || {};
        const incomes = currentKycData.incomes || [];
        const income = incomes.find((income) => income.type === incomeType);
        income!.isRequiredConfimation = 'yes';

        selectedPerson!.kycData = {
          ...currentKycData,
          incomes: incomes,
        };

        updateIncorporationById({
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        });
        onUpdate();
      },
    });
  };

  const getCountryNameByCode = (code: string | string[]) => {
    let values = [];
    if (Array.isArray(code)) {
      values = code;
    } else {
      values = [code];
    }
    return values
      .map((val) => {
        const countryData = countries.find((pair) => pair.code === val);
        return countryData?.name || val;
      })
      .join(', ');
  };

  const showForm = () => {
    setIsRequestChangesFormVisible(true);
  };

  const hideForm = (event: React.MouseEvent) => {
    event.preventDefault();

    setIsRequestChangesFormVisible(false);
  };

  const markFailedBiometricCheck = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () => {
        const currentKycData = selectedPerson!.kycData || {};
        selectedPerson!.kycData = {
          ...currentKycData,
          hasFailedBiometricCheck: true,
          hasCompletedBiometricCheck: false,
        };

        updateIncorporationById({
          parameter: {
            companyId: companyId!,
          },
          requestBody: {
            people: people,
          },
        });
        onUpdate();
      },
    });
  };

  const handleFinish = (values: Schemas.KYCData) => {
    const currentKycData = selectedPerson!.kycData || {};
    selectedPerson!.kycData = {
      ...currentKycData,
      ...values,
    };

    updateIncorporationById({
      parameter: {
        companyId: companyId!,
      },
      requestBody: {
        people: incorporation.people,
      },
    });

    onUpdate();
  };

  const errorForm = (field: string) => (
    <>
      {!isRequestChangesFormVisible && (
        <Flex gap={20}>
          <Button type="link" onClick={showForm}>
            Request changes
          </Button>
        </Flex>
      )}

      {isRequestChangesFormVisible && (
        <Flex gap={20} vertical>
          <Form
            layout="vertical"
            onFinish={handleFinish}
            initialValues={selectedPerson?.kycData}
          >
            <Form.Item label="Reason" name={`${field}ErrorCode`}>
              <Select
                variant="outlined"
                placeholder="Select type of issue"
                options={[
                  {
                    label: 'Wrong value',
                    value: 'wrong_value',
                  },
                  {
                    label: 'Needs detailed description',
                    value: 'missing_description',
                  },
                  {
                    label: 'Needs more detailed description',
                    value: 'not_clear_description',
                  },
                ]}
                size="large"
              />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Request changes
            </Button>
            <Button type="link" onClick={(event) => hideForm(event)}>
              Cancel
            </Button>
          </Form>
        </Flex>
      )}
    </>
  );

  const renderFiles = (section: string) => {
    const files = selectedPerson?.files?.[section];

    return files?.length ? (
      <S.InfoRow>
        <S.InfoLabel>Files</S.InfoLabel>
        <S.InfoDelimiter />
        <S.InfoValue>
          <List
            dataSource={files}
            renderItem={(file, index) => (
              <List.Item>
                {index + 1}. {getStatusDescription(file)}
                <Button
                  onClick={() => {
                    selectFile(file);
                  }}
                  type="link"
                >
                  View
                </Button>
                {file.events.length ? (
                  <List
                    dataSource={file.events}
                    renderItem={(event) => (
                      <List.Item>
                        {event.type.toLocaleUpperCase()} by{' '}
                        {[event.user.firstName, event.user.lastName].join(' ')}{' '}
                        on {dayjs(event.createdAt).format('DD.MM.YYYY HH:mm')}
                      </List.Item>
                    )}
                  />
                ) : null}
              </List.Item>
            )}
          />
        </S.InfoValue>
      </S.InfoRow>
    ) : null;
  };

  const isLegal = selectedPerson?.type === 'legal';
  const isNatural = !isLegal;
  const isShareholderOrUbo =
    selectedPerson?.isShareholder || selectedPerson?.isUbo;

  return (
    <>
      {activeFile && (
        <PersonFileModal
          open={isOpenPersonFileModal}
          file={activeFile!}
          title="File"
          onCancel={closePersonFile}
        />
      )}
      <Flex gap={24}>
        <S.People>
          {people
            .filter((person) => !person.isCompanyItself)
            .map((person) => (
              <S.PeopleWrap key={person.id}>
                <S.Person
                  onClick={() => handleSelectPerson(person)}
                  selected={selectedPerson?.id === person.id}
                >
                  <Flex gap={16}>
                    {person.type === 'legal' ? <IconLegal /> : <IconUser />}

                    <Flex vertical>
                      <S.PersonName>{person.name}</S.PersonName>
                      <S.PersonDetails>{getPersonRole(person)}</S.PersonDetails>
                    </Flex>
                  </Flex>

                  <Flex align="center" gap={4}>
                    {hasErrorPerson(person) && (
                      <S.StepNumber status="errorsFound">
                        <IconWarningTransparent />
                      </S.StepNumber>
                    )}
                  </Flex>
                </S.Person>
              </S.PeopleWrap>
            ))}
        </S.People>

        <S.Files>
          {selectedPerson && (
            <Flex gap={16} vertical>
              <Flex justify="flex-end">
                <Button onClick={() => setIsEditModalOpen(true)}>Edit</Button>
              </Flex>
              {isLegal && (
                <>
                  <InfoRow label="TIN" value={selectedPerson.kycData?.tin} />
                  {selectedPerson.kycData?.tinErrorCode &&
                    `- ${selectedPerson.kycData?.tinErrorCode}`}
                  {errorForm('tin')}
                </>
              )}

              {isLegal && (
                <InfoBlock title="Country" status={getStepStatus('country')}>
                  <InfoRow
                    label="Country"
                    value={
                      selectedPerson.kycData?.countryCode &&
                      getCountryNameByCode(selectedPerson.kycData?.countryCode)
                    }
                  />
                </InfoBlock>
              )}

              {isNatural && (
                <InfoBlock
                  title="Nationality and surname"
                  status={getStepStatus('nationality')}
                >
                  <InfoRow
                    label="Nationalities"
                    value={
                      selectedPerson.kycData?.nationality &&
                      getCountryNameByCode(selectedPerson.kycData?.nationality)
                    }
                  />

                  <InfoRow
                    label="Had another surname?"
                    value={selectedPerson.kycData?.isUsedAnotherName}
                  />

                  <InfoRow
                    label="Another surname reasons"
                    value={selectedPerson.kycData?.anotherNameReasons}
                  />
                </InfoBlock>
              )}

              {isNatural && (
                <InfoBlock title="Biometric check" status={getStepStatus('id')}>
                  <InfoRow
                    label="Completed check"
                    value={
                      selectedPerson.kycData?.hasCompletedBiometricCheck
                        ? 'Yes'
                        : 'No'
                    }
                  />

                  {loadingOnfido && <Loader />}

                  {onfidoData && (
                    <>
                      <S.InfoRow>
                        <S.InfoLabel>Status</S.InfoLabel>
                        <S.InfoDelimiter />
                        <div>{onfidoData.workflowRunStatus}</div>
                      </S.InfoRow>
                      <div>
                        <a
                          href={`https://dashboard.onfido.com/results/${onfidoData.workflowRunId}`}
                          target="_blank"
                        >
                          View on Onfido
                        </a>
                      </div>

                      <div>
                        <Button
                          type="primary"
                          onClick={markFailedBiometricCheck}
                        >
                          Mark as failed for client
                        </Button>
                      </div>
                    </>
                  )}
                </InfoBlock>
              )}

              {isNatural && (
                <InfoBlock
                  title="Proof of address"
                  status={getStepStatus('address_proof')}
                >
                  <InfoRow
                    label="Country"
                    value={
                      selectedPerson.kycData?.countryCode &&
                      getCountryNameByCode(selectedPerson.kycData?.countryCode)
                    }
                  />

                  <InfoRow
                    label="Address"
                    value={selectedPerson.kycData?.address}
                  />

                  <InfoRow
                    label="Zip code"
                    value={selectedPerson.kycData?.zip}
                  />

                  <InfoRow label="City" value={selectedPerson.kycData?.city} />

                  {renderFiles('address_proof')}
                </InfoBlock>
              )}

              {isNatural && isShareholderOrUbo && (
                <InfoBlock
                  title="Source of wealth"
                  status={getStepStatus('wealth')}
                >
                  <InfoRow
                    label="Total wealth"
                    value={selectedPerson.kycData?.totalWealth}
                  />

                  {selectedPerson.kycData?.professionalIncome && (
                    <InfoRow
                      label="Professional Income"
                      value={selectedPerson.kycData?.professionalIncome}
                    />
                  )}

                  <S.InfoRow>
                    <S.InfoLabel>Incomes</S.InfoLabel>
                    <S.InfoDelimiter />
                    <S.InfoValue>
                      <Flex gap={8} vertical>
                        {selectedPerson.kycData?.incomes &&
                          selectedPerson.kycData?.incomes
                            .filter(Boolean)
                            .map((income, index) => (
                              <Flex key={index} gap={4} vertical>
                                <Flex vertical>
                                  {income.type}: {income.sumAndDescription}
                                  {income.isRequiredConfimation === 'yes' ? (
                                    ' - Doc requested'
                                  ) : (
                                    <Button
                                      type="link"
                                      onClick={() =>
                                        requestDocsForIncome(income.type)
                                      }
                                    >
                                      Request docs
                                    </Button>
                                  )}
                                  {(
                                    selectedPerson.files?.[
                                      `wealth_${income.type}`
                                    ] || []
                                  ).map((file: CompanyFile, index) => (
                                    <Flex gap={4} vertical>
                                      <Button
                                        onClick={() => {
                                          selectFile(file);
                                        }}
                                        type="link"
                                      >
                                        View file {index + 1}
                                      </Button>
                                      {file.events.length ? (
                                        <List
                                          dataSource={file.events}
                                          renderItem={(event) => (
                                            <List.Item>
                                              {event.type.toLocaleUpperCase()}{' '}
                                              by{' '}
                                              {[
                                                event.user.firstName,
                                                event.user.lastName,
                                              ].join(' ')}{' '}
                                              on{' '}
                                              {dayjs(event.createdAt).format(
                                                'DD.MM.YYYY HH:mm'
                                              )}
                                            </List.Item>
                                          )}
                                        />
                                      ) : null}
                                    </Flex>
                                  ))}
                                </Flex>
                              </Flex>
                            ))}
                      </Flex>
                    </S.InfoValue>
                  </S.InfoRow>
                  <div>{selectedPerson.kycData?.wealthErrorCode}</div>
                  {errorForm('wealth')}
                </InfoBlock>
              )}

              {isNatural && isShareholderOrUbo && (
                <InfoBlock title="CV" status={getStepStatus('cv')}>
                  <InfoRow
                    label="Considered a Politically Exposed Person (PEP)?"
                    value={selectedPerson.kycData?.pep}
                  />

                  {selectedPerson.kycData?.pep && (
                    <InfoRow
                      label="PEP info"
                      value={selectedPerson.kycData?.pepInfo}
                    />
                  )}

                  <InfoRow
                    label="Works or has companies in countries blacklisted by the FATF,
                  UN, EU, OECD or perceived as corrupt?"
                    value={selectedPerson.kycData?.blacklist}
                  />

                  {selectedPerson.kycData?.blacklist && (
                    <InfoRow
                      label="Blacklist info"
                      value={selectedPerson.kycData?.blacklistInfo}
                    />
                  )}

                  <InfoRow
                    label="Personal phone"
                    value={selectedPerson.kycData?.personalPhone}
                  />

                  <InfoRow
                    label="Personal email"
                    value={selectedPerson.kycData?.personalEmail}
                  />

                  <InfoRow
                    label="Activity sector"
                    value={selectedPerson.kycData?.activitySector}
                  />
                  {renderFiles('cv')}
                </InfoBlock>
              )}

              {isNatural && isShareholderOrUbo && (
                <InfoBlock
                  title="Tax information"
                  status={getStepStatus('tax')}
                >
                  <InfoRow
                    label="Country of tax residence"
                    value={
                      selectedPerson.kycData?.taxResidenceCountryCode &&
                      getCountryNameByCode(
                        selectedPerson.kycData?.taxResidenceCountryCode
                      )
                    }
                  />

                  <InfoRow
                    label="Tax number"
                    value={selectedPerson.kycData?.taxNumber}
                  />

                  <InfoRow
                    label="Country of professional income"
                    value={
                      selectedPerson.kycData?.professionalIncomeCountryCode &&
                      getCountryNameByCode(
                        selectedPerson.kycData?.professionalIncomeCountryCode
                      )
                    }
                  />

                  <InfoRow
                    label="Has any links with the USA?"
                    value={selectedPerson.kycData?.usaLink}
                  />

                  {selectedPerson.kycData?.usaLink && (
                    <InfoRow
                      label="USA link type"
                      value={selectedPerson.kycData?.usaLinkType}
                    />
                  )}
                </InfoBlock>
              )}

              {isLegal && (
                <InfoBlock title="Funds" status={getStepStatus('funds')}>
                  <InfoRow
                    label="Filed annual accounts before?"
                    value={selectedPerson.kycData?.hasFiledAnnualAccounts}
                  />

                  <InfoRow
                    label="Has external funding?"
                    // @ts-expect-error-next-line
                    value={selectedPerson.kycData?.hasExternalFunds}
                  />

                  <InfoRow
                    label="External funding description"
                    // @ts-expect-error-next-line
                    value={selectedPerson.kycData?.externalFundsBackground}
                  />
                </InfoBlock>
              )}

              {isLegal && (
                <>
                  <InfoBlock title="Extract from commercial register">
                    {renderFiles('extract')}
                  </InfoBlock>
                  <InfoBlock title="Articles of Association (AoA) or deed">
                    {renderFiles('aoa')}
                  </InfoBlock>
                  <InfoBlock title="Last annual accounts">
                    {renderFiles('annual_accounts')}
                  </InfoBlock>
                  <InfoBlock title="Chart with ownership and %">
                    {renderFiles('ownership_chart')}
                  </InfoBlock>
                </>
              )}
            </Flex>
          )}
        </S.Files>

        {contextHolder}

        {selectedPerson && (
          <IncorporationKYCEditModal
            isOpen={isEditModalOpen}
            person={selectedPerson}
            onSubmit={handleFinish}
            onClose={() => setIsEditModalOpen(false)}
          />
        )}
      </Flex>
    </>
  );
};

export default IncorporationKYC;
