import { useAdminCompanyController_findOneById } from '@api-client/generated/AdminCompanyController/findOneById';

import { default as useCurrentCompanyId } from './useCurrentCompanyId';

const useCurrentCompany = () => {
  const companyId = useCurrentCompanyId();

  return useAdminCompanyController_findOneById({
    params: {
      id: companyId,
    },
  });
};

export default useCurrentCompany;
