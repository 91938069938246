import styled from '@emotion/styled';
import { colors } from '@theme';
import { Flex } from 'antd';

type Status = 'inProgress' | 'completed' | 'errorsFound' | null;

type PersonProps = {
  selected: boolean;
  type?: 'sub';
};

type StepProps = {
  status: Status;
  selected: boolean;
};

const getBadgeColorByStatus = (status?: Status, selected?: boolean) => {
  if (status === 'completed' || status === 'inProgress' || selected) {
    return {
      bg: colors.primary,
      color: colors.white,
    };
  }

  if (status === 'errorsFound') {
    return {
      bg: colors.red,
      color: colors.white,
    };
  }

  return {
    bg: colors.strokeGrey,
    color: colors.blackSecondary,
  };
};

export const People = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const PeopleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Person = styled.div<PersonProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.strokeGrey};
  box-shadow: 0 0 0 1px
    ${({ theme, selected }) =>
      selected ? theme.colors.primary : 'transparent'};
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 12px 16px;
  margin-left: ${({ type }) => (type === 'sub' ? '40px' : 0)};

  & > svg {
    color: ${({ theme, selected }) =>
      selected ? theme.colors.primary : theme.colors.blackSecondary};
  }
`;

export const PersonName = styled.div`
  font-weight: 600;
`;

export const PersonDetails = styled.div`
  color: ${({ theme }) => theme.colors.blackSecondary};
  font-size: 12px;
`;

export const Files = styled.div`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 16px;
  padding: 24px;
  flex: 1;
`;

export const InfoBlockRoot = styled.div`
  padding: 8px;
  border-radius: 8px;
  border: 1px solid ${({ theme }) => theme.colors.strokeGrey};
`;

export const InfoBlockTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  padding-left: 8px;
  margin: 0;
`;

export const InfoRow = styled.div`
  display: grid;
  grid-template-columns: minmax(auto, 200px) 1fr minmax(250px, 50%);
  grid-gap: 16px;
  padding: 4px 8px;
  border-radius: 4px;
  
  &:hover {
    background-color: ${({ theme }) => theme.colors.bgDarkGrey};
  }
`;

export const InfoDelimiter = styled.div`
  height: 1px;
  transform: translateY(10px);
  border-bottom: 1px dotted ${({ theme }) => theme.colors.strokeGrey};
`;

export const InfoLabel = styled.div`
  color: ${({ theme }) => theme.colors.text300}
`;

export const InfoValue = styled(Flex)`
  gap: 8px;
  
  & > form {
    flex: 1;
    
    & > * {
      width: 100%;
    }
  }
`;

export const InfoIcon = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
  display: inline-flex;
  width: 20px;
  height: 20px;
  align-items: center;
  color: ${({ theme }) => theme.colors.text500};
  cursor: pointer;
  opacity: 0.5;
  
  &:hover {
    opacity: 1;
  }
`;

export const StepNumber = styled.div<Partial<StepProps>>`
  min-width: 20px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).bg};
  color: ${({ selected, status }) =>
    getBadgeColorByStatus(status, selected).color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
`;

export const StepName = styled.div`
  font-weight: 600;
  font-size: 12px;

  .ui-typography {
    font-size: 12px;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.blackSecondary};
  }
`;
