import { Schemas } from '@api-client/generated/types';

type Person = Schemas.Person;

export const flattenDeep = (people: Person[]): Person[] =>
  people.reduce((acc, person) => {
    person.copyOfId = null;
    person.sameAsIds = [];
    return person.people?.length
      ? acc.concat(flattenDeep(person.people)).concat(person)
      : acc.concat(person);
  }, [] as Person[]);

export const deduplicatePeople = (people: Person[]): Person[] =>
  people
    .reduce((acc, person) => {
      for (const el of acc) {
        if (el.name.toLowerCase() === person.name.toLowerCase()) {
          el.sameAsIds.push(person.id);
          person.copyOfId = el.id;
        }
      }

      acc.push(person);
      return acc;
    }, [] as Person[])
    .filter((person) => !person.copyOfId);
