import { Flex } from 'antd';
import { NavLink, Outlet, useParams } from 'react-router-dom';

import * as S from './styled';

const LINKS = [
  {
    link: '',
    label: 'Onboarding',
    end: true,
  },
  {
    link: '/info',
    label: 'Company info',
  },
  {
    link: '/transactions',
    label: 'Transactions',
  },
  {
    link: '/documents',
    label: 'Documents',
  },
  {
    link: '/billing',
    label: 'Billing',
  },
  {
    link: '/accounts',
    label: 'Accounts',
  },
  {
    link: '/legal',
    label: 'Legal',
  },
  {
    link: '/payslips',
    label: 'Payslips',
  },
  {
    link: '/taxes',
    label: 'VAT & Taxes',
  },
];

const LayoutCompany = () => {
  const { companyId } = useParams<{ companyId: string }>();

  return (
    <S.Root gap={16} vertical>
      {companyId && (
        <Flex gap="4px 32px" wrap="wrap">
          {LINKS.map((link) => (
            <NavLink key={link.link} end={link.end} to={`/companies/${companyId}${link.link}`}>
              {({ isActive }) => (
                <S.Title isActive={isActive} level={5}>
                  {link.label}
                </S.Title>
              )}
            </NavLink>
          ))}
        </Flex>
      )}
      <Outlet />
    </S.Root>
  );
};

export default LayoutCompany;
