import { FC } from 'react';

import { colors } from '@theme';
import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type Status = Schemas.Document['adminStatus'];
type BobStatus = Schemas.Document['bobStatus'];

type StatusTagProps = {
  status: Status;
  bobStatus?: BobStatus;
}

type ParamsByStatus = {
  label: string;
  bgColor: string;
  textColor: string;
}

const getParamsByStatus = (status: Status, bobStatus?: BobStatus): ParamsByStatus => {
  switch (status) {
    case 'ready':
      return {
        label: 'New',
        bgColor: colors.strokeDarkGrey,
        textColor: colors.textBlue400,
      };

    case 'processing':
      return {
        label: bobStatus === 'pending' ? 'In work (automatic)' : 'In work (manual)',
        bgColor: colors.bgBrightBlue,
        textColor: colors.link,
      };

    case 'requested_changes':
      return {
        label: 'Error',
        bgColor: colors.iconWarning,
        textColor: colors.error,
      };

    case 'processed':
      return {
        label: 'Booked',
        bgColor: colors.greenLight,
        textColor: colors.success,
      };
    default:
      return {
        label: 'New',
        bgColor: colors.strokeDarkGrey,
        textColor: colors.textBlue400,
      };
  }
};

const DocumentsStatusTag: FC<StatusTagProps> = ({ status, bobStatus }) => {
  if (!status) {
    return null;
  }

  const { label, bgColor, textColor } = getParamsByStatus(status, bobStatus);

  return (
    <S.Status bgColor={bgColor} textColor={textColor}>
      {label}
    </S.Status>
  );
};

export default DocumentsStatusTag;
