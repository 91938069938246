import {
  Button,
  Checkbox,
  Flex,
  Form,
  Input,
  Modal,
  Select,
  Typography,
} from 'antd';

type FormValues = {
  type: string;
  name: string;
  shares: number;
  isDirector: boolean;
  isSigner: boolean;
};

type CompanyDetailsShareholderEditProps = {
  open: boolean;
  totalShares: number;
  onCancel: () => void;
  onSubmit: (values: FormValues) => void;
};

const addShareholderOrUboSelectOptions = [
  {
    label: 'Natural person',
    value: 'natural',
  },
  {
    label: 'Legal person',
    value: 'legal',
  },
];

const CompanyDetailsShareholderEdit = (
  props: CompanyDetailsShareholderEditProps
) => {
  const [form] = Form.useForm<FormValues>();

  const handleSubmit = (values: FormValues) => {
    props.onSubmit(values);
    props.onCancel();
    form.resetFields();
  };

  const personShares = Form.useWatch('shares', form);

  const totalShares = props.totalShares + (Number(personShares) || 0);

  return (
    <Modal
      title="Creating shareholder"
      footer={null}
      open={props.open}
      onCancel={props.onCancel}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        initialValues={{ type: 'natural', shares: 0 }}
        layout="vertical"
      >
        <Form.Item name="type" label="Type">
          <Select options={addShareholderOrUboSelectOptions} size="large" />
        </Form.Item>
        <Form.Item name="name" label="Name" rules={[{ required: true }]}>
          <Input size="large" placeholder="Full name" />
        </Form.Item>
        <Form.Item name="shares" label="Shares" trigger="onChange">
          <Input size="large" type="number" max="100" min="0" />
        </Form.Item>
        <Flex gap={16}>
          <Form.Item
            name="isDirector"
            layout="horizontal"
            valuePropName="checked"
          >
            <Checkbox>Manager role</Checkbox>
          </Form.Item>
          <Form.Item
            name="isSigner"
            layout="horizontal"
            valuePropName="checked"
          >
            <Checkbox>Authorised person</Checkbox>
          </Form.Item>
        </Flex>
        <Form.Item>
          <Typography.Text type={totalShares > 100 ? 'danger' : 'secondary'}>
            Total shares: {totalShares}
          </Typography.Text>
        </Form.Item>
        <Flex gap={16} justify="end">
          <Button size="large" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            htmlType="submit"
            size="large"
            type="primary"
            onClick={form.submit}
          >
            Submit
          </Button>
        </Flex>
      </Form>
    </Modal>
  );
};

export default CompanyDetailsShareholderEdit;
