import { Button, TableColumnType } from 'antd';

import { Schemas } from '@api-client/generated/types';
import { IconTrash } from '@assets';

type ColumnsParams = {
  onDelete: (id: string) => void;
};

export const columns = (
  params: ColumnsParams
): TableColumnType<Schemas.Person>[] => [
  {
    key: 'type',
    dataIndex: 'type',
    title: 'Type',
  },
  {
    key: 'name',
    dataIndex: 'name',
    title: 'Name',
  },
  {
    key: 'shares',
    dataIndex: 'shares',
    title: 'Shares',
  },
  {
    key: 'isDirector',
    dataIndex: 'isDirector',
    title: 'Director',
    render: (isDirector) => (isDirector ? 'Yes' : 'No'),
  },
  {
    key: 'controls',
    render: (_, shareholder) => (
      <Button type="link" onClick={() => params.onDelete(shareholder.id)}>
        <IconTrash />
      </Button>
    ),
  },
];
