import { Flex } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import { useLocation, useNavigate } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';
import { IconCheck } from '@assets';
import { DEFAULT_CURRENCY_CODE } from '@constants';
import { Amount } from '@entities';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';

type BillingPeriodListItemProps = {
  type: 'period';
  details: Schemas.BillingPeriod;
};

type BillingOneTimeInvoiceListItemProps = {
  type: 'one-time';
  details: Schemas.BillingDocument;
};

type BillingListItemProps = {
  selectedId: string;
} & (BillingPeriodListItemProps | BillingOneTimeInvoiceListItemProps);

const BillingListItem = (props: BillingListItemProps) => {
  const navigate = useNavigate();
  const { search } = useLocation();
  const companyId = useCurrentCompanyId();

  const handleSelect = (id: string) => {
    switch (props.type) {
      case 'period':
        navigate(`/companies/${companyId}/billing/${id}${search}`);
        break;
      case 'one-time':
        navigate(`/companies/${companyId}/one-time-billing/${id}${search}`);
        break;
    }
  };

  const getDatePeriod = (period: string) => dayjs(period).format('DD.MM.YYYY');

  switch (props.type) {
    case 'period':
      return (
        <S.Item
          gap={6}
          isSelected={props.selectedId === props.details.id}
          onClick={() => handleSelect(props.details.id)}
          vertical
        >
          <Flex align="flex-start" justify="space-between" gap={8}>
            <S.Title>
              {getDatePeriod(props.details.startDate)} -{' '}
              {getDatePeriod(props.details.endDate)}
            </S.Title>

            {props.details.billingDocument ? (
              <S.Status status="isSuccess" align="center" gap={4}>
                Issued
                <IconCheck />
              </S.Status>
            ) : (
              <S.Status>Ongoing</S.Status>
            )}
          </Flex>

          <S.ServicesCount>
            {props.details.items.length} services
          </S.ServicesCount>

          <Amount
            currencyCode={DEFAULT_CURRENCY_CODE}
            amount={_.sumBy(props.details.items, 'totalPrice')}
            withoutType
          />
        </S.Item>
      );

    case 'one-time': {
      return (
        <S.Item
          gap={6}
          isSelected={props.selectedId === props.details.id}
          onClick={() => handleSelect(props.details.id)}
          vertical
        >
          <Flex align="flex-start" justify="space-between" gap={8}>
            <S.Title>
              {props.details.documentMetadata.issueDate &&
                getDatePeriod(props.details.documentMetadata.issueDate)}
            </S.Title>
          </Flex>

          <S.ServicesCount>
            {props.details.documentMetadata.items?.length} services
          </S.ServicesCount>

          <Amount
            currencyCode={DEFAULT_CURRENCY_CODE}
            amount={_.sumBy(props.details.documentMetadata.items, 'totalPrice')}
            withoutType
          />
        </S.Item>
      );
    }
  }
};

export default BillingListItem;
