import styled from '@emotion/styled';
import { Flex } from 'antd';

export const Root = styled.div`
  max-width: 900px;
`;

export const CompanyInfoDocuments = styled(Flex)`
  margin-top: 32px;
`;

export const CompanyInfoDocumentsBody = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr;
  gap: 16px;
`;
