import { Flex } from 'antd';
import { capitalize } from 'lodash';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';
import { CompanyDetailsInfoCell } from '@entities';

import * as S from './styled';

type CompanyDetailsServiceProps = {
  company: Schemas.AdminCompanyDto;
  onEdit: () => void;
};

const CompanyDetailsServiceInfo: FC<CompanyDetailsServiceProps> = ({
  company,
  onEdit,
}) => (
  <S.Container>
    <S.Edit onClick={onEdit}>
      <IconEditUnderline width={16} height={16} /> Edit
    </S.Edit>

    <Flex gap={16}>
      <CompanyDetailsInfoCell
        label="Status"
        value={capitalize(company.status?.split('_').join(' '))}
        isVisible
      />

      <CompanyDetailsInfoCell
        label="Billing"
        value={
          company.billingSettings?.isBillingEnabled ? 'Enabled' : 'Disabled'
        }
        isVisible
      />
    </Flex>

    <Flex gap={16}>
      <CompanyDetailsInfoCell
        label="Base period price"
        value={company.billingSettings?.baseServicePrice || '-'}
        isVisible
      />

      <CompanyDetailsInfoCell
        label="Documents included"
        value={company.billingSettings?.documentsIncluded || '-'}
        isVisible
      />
    </Flex>

    <Flex gap={16}>
      <CompanyDetailsInfoCell
        label="Payslip price"
        value={company.billingSettings?.payslipPrice || '-'}
        isVisible
      />

      <CompanyDetailsInfoCell
        label="Payslips amount"
        value={company.billingSettings?.payslipAmount || '-'}
        isVisible
      />
    </Flex>
  </S.Container>
);

export default CompanyDetailsServiceInfo;
