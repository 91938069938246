import {
  Button,
  Col,
  Flex,
  List,
  Modal,
  Row,
  Table,
  type TableColumnType,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import type { PartialDeep } from 'type-fest';

import { useAdminCompanyController_updateOneById } from '@api-client/generated/AdminCompanyController/updateOneById';
import { useAdminIncorporationController_approveKyc } from '@api-client/generated/AdminIncorporationController/approveKyc';
import { useAdminIncorporationController_failKyc } from '@api-client/generated/AdminIncorporationController/failKyc';
import { useAdminIncorporationController_findOneById } from '@api-client/generated/AdminIncorporationController/findOneById';
import { useAdminIncorporationController_updateOneById } from '@api-client/generated/AdminIncorporationController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { Card, InfoCell, Loader } from '@components';
import { AddressKYC, IncorporationKYC } from '@entities';
import { useAccount, useCurrentCompanyId } from '@hooks';

import * as S from './styled';

const { Title } = Typography;

type Incorporation = PartialDeep<Schemas.Incorporation>;
type IncorporationDto = PartialDeep<Schemas.AdminIncorporationDto>;

type IncorporationWithDto = Incorporation | IncorporationDto;
type Person = Schemas.Person;

const Incorporation: FC = () => {
  const { account } = useAccount();

  const companyId = useCurrentCompanyId();

  const [modal, contextHolder] = Modal.useModal();

  const [incorporation, setIncorporation] =
    useState<IncorporationWithDto | null>(null);

  const {
    data,
    isPending: loading,
    refetch,
  } = useAdminIncorporationController_findOneById({
    params: {
      companyId,
    },
  });

  useEffect(() => {
    if (data) {
      setIncorporation(data);
    }
  }, [data]);

  const { mutate: updateIncorporationById } =
    useAdminIncorporationController_updateOneById();
  const { mutate: updateCompany } = useAdminCompanyController_updateOneById();
  const { mutate: approveKYC } = useAdminIncorporationController_approveKyc();
  const { mutate: failKYC } = useAdminIncorporationController_failKyc();

  const updateIncorporation = () =>
    refetch();

  const columns: TableColumnType<Schemas.Person>[] = [
    {
      key: 'type',
      dataIndex: 'type',
      title: 'Type',
    },
    {
      key: 'name',
      dataIndex: 'name',
      title: 'Name',
    },
    {
      key: 'shares',
      dataIndex: 'shares',
      title: 'Shares',
    },
    {
      key: 'isDirector',
      dataIndex: 'isDirector',
      title: 'Director',
      render: (isDirector) => (isDirector ? 'Yes' : 'No'),
    },
  ];

  if (!incorporation || loading) {
    return <Loader />;
  }

  const flattenDeep = (people: Person[]): Person[] =>
    people.reduce((acc, person) => {
      person.copyOfId = null;
      person.sameAsIds = [];
      return person.people?.length
        ? acc.concat(flattenDeep(person.people)).concat(person)
        : acc.concat(person);
    }, [] as Person[]);

  const deduplicatePeople = (people: Person[]): Person[] =>
    people
      .reduce((acc, person) => {
        for (const el of acc) {
          if (el.name.toLowerCase() === person.name.toLowerCase()) {
            el.sameAsIds.push(person.id);
            person.copyOfId = el.id;
          }
        }

        acc.push(person);
        return acc;
      }, [] as Person[])
      .filter((person) => !person.copyOfId);

  const people = deduplicatePeople(
    flattenDeep(incorporation.people as Person[])
  );

  const notifyFailedAddressProof = () => {
    modal.confirm({
      title: 'Are you sure you want to do this?',
      content: 'We will send an email to the client after you click "Ok".',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isAddressProofFailed: true,
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const notifyFailedKYC = () => {
    modal.confirm({
      title: 'Are you sure?',
      content: 'We will send an email to the client after you click "Ok".',
      onOk: () =>
        failKYC(
          {
            parameter: {
              companyId,
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const confirmKYC = () => {
    modal.confirm({
      title: 'Are you sure?',
      content: 'It will just mark that you approved the KYC documents',
      onOk: () =>
        approveKYC(
          {
            parameter: {
              companyId,
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const notifyPassedKyc = () => {
    modal.confirm({
      title: 'Are you sure?',
      content: 'It will send an email to client and move his status further',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isCheckComplete: true,
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const notifyAllSigned = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isAgreementSigned: true,
              group: 'payment',
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const markAsPaid = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isPaid: true,
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const markAsShareCapitalConfirmed = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isShareCapitalConfirmed: true,
              group: 'rcs',
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
            },
          }
        ),
    });
  };

  const markAsIncorporated = () => {
    modal.confirm({
      title: 'Are you sure?',
      onOk: () =>
        updateIncorporationById(
          {
            parameter: {
              companyId,
            },
            requestBody: {
              isIncorporated: true,
              group: 'incorporated',
            },
          },
          {
            onSuccess: () => {
              updateIncorporation();
              const status =
                // @ts-expect-error-next-line
                incorporation.incorporationType === 'incorporation'
                  ? 'incorporated'
                  : 'in_service';
              updateCompany({
                parameter: {
                  id: companyId,
                },
                requestBody: {
                  status,
                } as Schemas.AdminCompanyDto,
              });
            },
          }
        ),
    });
  };

  const kycStatus = (incorporation: IncorporationWithDto) => {
    if (incorporation.isKycFailed) {
      return 'Failed';
    }

    if (incorporation.isCheckComplete) {
      return 'Passed';
    }

    // @ts-expect-error-next-line
    if (incorporation.isSubmittedToKyc) {
      return 'Submitted';
    }

    return 'Pending';
  };

  return (
    <S.Container>
      <Flex gap={32} vertical>
        <Title>Incorporation Data</Title>
        <Flex gap={30} vertical>
          <Row gutter={[48, 30]}>
            <Col span={24}>
              <Card title="Company Details">
                <Row gutter={[60, 30]}>
                  <Col span={6}>
                    <InfoCell label="Stage" value={incorporation.group} />
                  </Col>
                  <Col span={6}>
                    <InfoCell
                      label="Name(s)"
                      // @ts-expect-error-next-line
                      value={incorporation.companyNames.join(', ')}
                    />
                  </Col>

                  <Col span={6}>
                    <InfoCell
                      label="Type" // @ts-expect-error-next-line
                      value={incorporation.companyType}
                    />
                  </Col>

                  <Col span={6}>
                    <InfoCell
                      label="KYC status"
                      value={kycStatus(incorporation)}
                    />
                  </Col>

                  <Col span={24}>
                    <InfoCell
                      label="Company activity"
                      // @ts-expect-error-next-line
                      value={incorporation.companyActivity}
                    />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Flex>
        <Flex gap={30} vertical>
          <Row gutter={[48, 30]}>
            <Col span={24}>
              <Card title="Persons">
                <Table
                  rowKey={({ id }) => id}
                  dataSource={
                    people.filter((person) => person.isShareholder) || []
                  }
                  columns={columns}
                  loading={false}
                  pagination={false}
                  scroll={{ x: 720 }}
                />
              </Card>
            </Col>
          </Row>
        </Flex>
        <Flex vertical>
          {account?.adminRole !== 'support' && (
            <Card title="KYC">
              <Flex gap={24} vertical>
                <IncorporationKYC
                  incorporation={incorporation}
                  people={people}
                  onUpdate={updateIncorporation}
                />
                <Flex gap={12}>
                  <Button type="link" onClick={notifyFailedKYC}>
                    Notify client of failed KYC
                  </Button>
                  <Button type="primary" onClick={confirmKYC}>
                    Appove KYC from your side
                  </Button>
                </Flex>
                {incorporation.isKycFailed && <div>Notification sent</div>}
                {
                  // @ts-expect-error-next-line
                  incorporation.events.length ? (
                    <List
                      dataSource={
                        // @ts-expect-error-next-line
                        incorporation.events
                      }
                      renderItem={(event) => (
                        <List.Item>
                          {
                            // @ts-expect-error-next-line
                            event.type.toLocaleUpperCase()
                          }{' '}
                          by{' '}
                          {
                            // @ts-expect-error-next-line
                            [event.user.firstName, event.user.lastName].join(
                              ' '
                            )
                          }{' '}
                          on{' '}
                          {
                            // @ts-expect-error-next-line
                            dayjs(event.createdAt).format('DD.MM.YYYY HH:mm')
                          }
                        </List.Item>
                      )}
                    />
                  ) : null
                }
              </Flex>
            </Card>
          )}
        </Flex>
        {
          // @ts-expect-error-next-line
          incorporation.usesOwnRegisteredAddress && (
            <Flex gap={30} vertical>
              <Card title="Own address">
                <AddressKYC
                  // @ts-expect-error-next-line
                  files={incorporation.files['address_proof'] || []}
                  // @ts-expect-error-next-line
                  address={incorporation.address}
                />
                <Button type="primary" onClick={notifyFailedAddressProof}>
                  Notify client of failed address proof
                </Button>
                {incorporation.isAddressProofFailed && (
                  <div>Notification sent</div>
                )}
              </Card>
            </Flex>
          )
        }
        {contextHolder}
      </Flex>

      {account?.adminRole !== 'support' && (
        <S.ActionBar>
          <Flex gap={30}>
            {!incorporation.isCheckComplete && (
              <Button type="primary" onClick={notifyPassedKyc}>
                KYC is passed
              </Button>
            )}
            {!incorporation.isAgreementSigned && (
              <Button type="primary" onClick={notifyAllSigned}>
                All parties signed
              </Button>
            )}
            {!incorporation.isPaid && (
              <Button type="primary" onClick={markAsPaid}>
                Mark as paid
              </Button>
            )}
            {
              // @ts-expect-error-next-line
              incorporation.companyType !== 'sarl-s' &&
                !incorporation.isShareCapitalConfirmed && (
                  <Button type="primary" onClick={markAsShareCapitalConfirmed}>
                    Share capital confirmed
                  </Button>
                )
            }
            {!incorporation.isIncorporated && (
              <Button type="primary" onClick={markAsIncorporated}>
                {
                  // @ts-expect-error-next-line
                  incorporation.incorporationType === 'incorporation'
                    ? 'INCORPORATED'
                    : 'ENROLLED'
                }
              </Button>
            )}
          </Flex>
        </S.ActionBar>
      )}
    </S.Container>
  );
};

export default Incorporation;
