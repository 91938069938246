import { Flex, Typography } from 'antd';

import { Select } from '@components';
import styled from '@emotion/styled';

const { Text } = Typography;

export const Card = styled(Flex)`
  background-color: ${({ theme }) => theme.colors.bgGrey};
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 12px;
`;

export const ExtraText = styled(Text)`
  font-size: 12px;
`;

export const LinesTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

export const LinesAdd = styled.a<{ disabled: boolean }>`
  font-weight: 600;
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
`;

export const Submit = styled(Flex)`
  border-top: 1px solid ${({ theme }) => theme.colors.bgDarkGrey};
  padding-top: 18px;
  margin-top: 8px;

  && {
    .ui-btn {
      height: 48px;
      font-size: 16px;
    }
  }
`;

export const SelectContacts = styled(Select)`
  && {
    .ui-select-arrow {
      transform: rotate(0);
    }
  }
`;

export const ErrorMessage = styled.div`
  color: #ff4d4f;
  font-weight: 500;
  margin-bottom: 16px;
  padding: 12px;
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  border-radius: 4px;
`;
