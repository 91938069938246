import { ReactNode } from 'react';

import * as S from './styled';

type InfoRowProps = {
  label: ReactNode;
  value: ReactNode;
};

export const InfoRow = (props: InfoRowProps) => (
  <S.InfoRow>
    <S.InfoLabel>{props.label}</S.InfoLabel>
    <S.InfoDelimiter />
    <S.InfoValue>{props.value}</S.InfoValue>
  </S.InfoRow>
);
