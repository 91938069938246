import { Button, Popover, type PopoverProps } from 'antd';
import { ReactNode, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminBobContactController_findAll } from '@api-client/generated/AdminBobContactController/findAll';
import { Schemas } from '@api-client/generated/types';
import { ScrollbarBase } from '@components';
import { DEFAULT_TIMEOUT_FOR_SEARCH } from '@constants';
import { ContactAction, ContactsSimpleList, SearchControl } from '@entities';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';

type BobContact = Schemas.BobContact;

type ContactsPopupListProps = {
  width?: number;
  selectedId?: string | null;
  hideCreateAction?: boolean;
  emptyText?: ReactNode;
  onSelect: (contact: BobContact) => void;
  onAfterCreate?: (contact: BobContact) => void;
} & PopoverProps;

const ContactsPopupList = (props: ContactsPopupListProps) => {
  const companyId = useCurrentCompanyId();

  const [open, setOpen] = useState(false);
  const [isContactCreateVisible, setIsContactCreateVisible] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const {
    data: contacts,
    isPending: loading,
    refetch,
  } = useAdminBobContactController_findAll({
    params: {
      companyId: companyId!,
      term: searchValue,
    },
  });

  const handleSearch = useDebouncedCallback((value) => {
    setSearchValue(value);
  }, DEFAULT_TIMEOUT_FOR_SEARCH);

  const handleAddNewContact = () => {
    setIsContactCreateVisible(true);
    setOpen(false);
    setSearchValue('');
  };

  const handleSelectContact = (contact: BobContact) => {
    props.onSelect(contact);

    setOpen(false);
    setSearchValue('');
  };

  const handleChangePopup = (status: boolean) => {
    setOpen(status);

    if (!status) {
      setSearchValue('');
    }
  };

  const handleAfterCreate = (contact: BobContact) => {
    refetch();
    props.onAfterCreate?.(contact);
  };

  return (
    <>
      <ContactAction
        open={isContactCreateVisible}
        onAfterCreate={handleAfterCreate}
        onCancel={() => setIsContactCreateVisible(false)}
      />

      <Popover
        open={open}
        content={
          <S.Content width={props.width} gap={4} vertical>
            <S.Inner>
              <SearchControl
                onChange={(e) => handleSearch(e.target.value)}
                fitWidth
              />
            </S.Inner>

            <S.Contacts>
              <ScrollbarBase height={276} withoutPadding>
                <ContactsSimpleList
                  selectedId={props.selectedId}
                  dataSource={contacts}
                  loading={loading}
                  onSelect={handleSelectContact}
                  emptyText={props.emptyText}
                />
              </ScrollbarBase>
            </S.Contacts>

            {!props.hideCreateAction && (
              <S.Inner>
                <Button onClick={handleAddNewContact} size="large" block>
                  Add new contact
                </Button>
              </S.Inner>
            )}
          </S.Content>
        }
        trigger="click"
        placement="bottom"
        arrow={false}
        onOpenChange={handleChangePopup}
        {...props}
      >
        {props.children}
      </Popover>
    </>
  );
};

export default ContactsPopupList;
