import {
  Button,
  Checkbox,
  Collapse,
  CollapseProps,
  Form,
  Input,
  Modal,
  Select,
} from 'antd';
import { useEffect, useMemo } from 'react';

import { Schemas } from '@api-client/generated/types';
import { countryOptions } from '@constants';
import { USA_LINK_TYPES } from '@entities/IncorporationKYC/constants';

import * as S from './styled';

type IncorporationKYCEditModalProps = {
  isOpen: boolean;
  person: Schemas.Person;
  onSubmit: (values: Schemas.Person['kycData']) => void;
  onClose: () => void;
};

const IncorporationKYCEditModal = (props: IncorporationKYCEditModalProps) => {
  const isLegal = props.person.type === 'legal';
  const isNatural = !isLegal;
  const isShareholderOrUbo = props.person.isShareholder || props.person.isUbo;

  const [form] = Form.useForm();

  const pep = Form.useWatch('pep', form);
  const blacklist = Form.useWatch('blacklist', form);
  const linksWithUSA = Form.useWatch('usaLink', form);

  useEffect(() => {
    form.setFieldsValue(props.person.kycData || {});
  }, [props.person, form, props.isOpen]);

  const sections: CollapseProps['items'] = useMemo(
    () =>
      [
        isNatural && {
          key: 'nationality',
          label: 'Nationality and surname',
          children: (
            <>
              <Form.Item name="nationality" label="Nationalities">
                <Select
                  options={countryOptions}
                  mode="multiple"
                  showSearch
                  virtual
                />
              </Form.Item>
              <Form.Item name="isUsedAnotherName" label="Had another surname?">
                <Select
                  options={[
                    { label: 'yes', value: 'yes' },
                    { label: 'no', value: 'no' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="anotherNameReasons"
                label="Another surname reasons"
              >
                <Input />
              </Form.Item>
            </>
          ),
        },
        isNatural && {
          key: 'biometric',
          label: 'Biometric check',
          children: (
            <>
              <Form.Item
                name="biometric"
                label="Completed check"
                valuePropName="checked"
                layout="horizontal"
              >
                <Checkbox />
              </Form.Item>
            </>
          ),
        },
        isNatural && {
          key: 'address_proof',
          label: 'Proof of address',
          children: (
            <>
              <Form.Item name="countryCode" label="Country">
                <Select options={countryOptions} showSearch virtual />
              </Form.Item>
              <Form.Item name="address" label="Address">
                <Input />
              </Form.Item>
              <Form.Item name="zip" label="Zip code">
                <Input />
              </Form.Item>
              <Form.Item name="city" label="City">
                <Input />
              </Form.Item>
            </>
          ),
        },
        isNatural &&
          isShareholderOrUbo && {
            key: 'wealth',
            label: 'Source of wealth',
            children: (
              <>
                <Form.Item name="totalWealth" label="Total wealth">
                  <Input type="number" />
                </Form.Item>
                <Form.Item
                  name="professionalIncome"
                  label="Professional Income"
                >
                  <Input type="number" />
                </Form.Item>
              </>
            ),
          },
        isNatural &&
          isShareholderOrUbo && {
            key: 'cv',
            label: 'CV',
            children: (
              <>
                <Form.Item
                  name="pep"
                  label="Considered a Politically Exposed Person (PEP)?"
                >
                  <Select
                    options={[
                      { label: 'yes', value: 'yes' },
                      { label: 'no', value: 'no' },
                    ]}
                  />
                </Form.Item>
                {pep === 'yes' && (
                  <Form.Item name="pepInfo" label="PEP info">
                    <Input />
                  </Form.Item>
                )}
                <Form.Item
                  name="blacklist"
                  label="Works or has companies in countries blacklisted by the FATF, UN, EU, OECD or perceived as corrupt?"
                >
                  <Select
                    options={[
                      { label: 'yes', value: 'yes' },
                      { label: 'no', value: 'no' },
                    ]}
                  />
                </Form.Item>
                {blacklist === 'yes' && (
                  <Form.Item name="blacklistInfo" label="Blacklist info">
                    <Input />
                  </Form.Item>
                )}
                <Form.Item name="personalPhone" label="Personal phone">
                  <Input />
                </Form.Item>
                <Form.Item name="personalEmail" label="Personal email">
                  <Input />
                </Form.Item>
                <Form.Item name="activitySector" label="Activity sector">
                  <Input />
                </Form.Item>
              </>
            ),
          },
        isNatural &&
          isShareholderOrUbo && {
            key: 'tax',
            label: 'Tax information',
            children: (
              <>
                <Form.Item
                  name="taxResidenceCountryCode"
                  label="Country of tax residence"
                >
                  <Select options={countryOptions} showSearch virtual />
                </Form.Item>
                <Form.Item name="taxNumber" label="Tax number">
                  <Input />
                </Form.Item>
                <Form.Item
                  name="professionalIncomeCountryCode"
                  label="Country of professional income"
                >
                  <Select options={countryOptions} showSearch virtual />
                </Form.Item>
                <Form.Item name="usaLink" label="Has any links with the USA?">
                  <Select
                    options={[
                      { label: 'yes', value: 'yes' },
                      { label: 'no', value: 'no' },
                    ]}
                  />
                </Form.Item>
                {linksWithUSA === 'yes' && (
                  <Form.Item name="usaLinkType" label="USA link type">
                    <Select options={USA_LINK_TYPES} showSearch virtual />
                  </Form.Item>
                )}
              </>
            ),
          },
        isLegal && {
          key: 'funds',
          label: 'Funds',
          children: (
            <>
              <Form.Item
                name="hasFiledAnnualAccounts"
                label="Filed annual accounts before?"
              >
                <Select
                  options={[
                    { label: 'yes', value: 'yes' },
                    { label: 'no', value: 'no' },
                  ]}
                />
              </Form.Item>
              <Form.Item name="hasExternalFunds" label="Has external funding?">
                <Select
                  options={[
                    { label: 'yes', value: 'yes' },
                    { label: 'no', value: 'no' },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="externalFundsBackground"
                label="External funding description"
              >
                <Input />
              </Form.Item>
            </>
          ),
        },
      ].filter(Boolean) as CollapseProps['items'],
    [blacklist, isLegal, isNatural, isShareholderOrUbo, linksWithUSA, pep]
  );

  const handleClose = () => {
    form.resetFields();
    props.onClose();
  };

  const handleSubmit = (values: Schemas.Person['kycData']) => {
    props.onSubmit(values);
    handleClose();
  };

  return (
    <>
      <Modal
        open={props.isOpen}
        title="Edit"
        onCancel={props.onClose}
        footer={null}
        destroyOnClose
      >
        <Form form={form} layout="vertical" onFinish={handleSubmit}>
          {isLegal && (
            <Form.Item label="TIN">
              <Input />
            </Form.Item>
          )}
          {isLegal && (
            <Form.Item label="Country">
              <Select
                defaultValue={props.person.kycData?.countryCode}
                options={countryOptions}
                showSearch
                virtual
              />
            </Form.Item>
          )}
          <Collapse
            items={sections}
            defaultActiveKey={(sections || []).map((s) => s.key as string)}
          />
          <S.Footer gap={16} justify="flex-end">
            <Button size="large" onClick={handleClose}>
              Cancel
            </Button>
            <Button size="large" type="primary" htmlType="submit">
              Update
            </Button>
          </S.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default IncorporationKYCEditModal;
