//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Response$AdminBillingPeriodController_findAllUnapproved$Status$200,
  ResponseContentType$AdminBillingPeriodController_findAllUnapproved,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminBillingPeriodController_findAllUnapproved = (options?: {
  config?: QueryOptions<
    Response$AdminBillingPeriodController_findAllUnapproved$Status$200[ResponseContentType$AdminBillingPeriodController_findAllUnapproved]
  >;
}) =>
  useApiClient<
    never,
    Response$AdminBillingPeriodController_findAllUnapproved$Status$200[ResponseContentType$AdminBillingPeriodController_findAllUnapproved]
  >({
    method: 'AdminBillingPeriodController_findAllUnapproved',
    config: options?.config,
  });
