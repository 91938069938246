//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminDocumentController_findLastParamsForContact,
  Response$AdminDocumentController_findLastParamsForContact$Status$200,
  ResponseContentType$AdminDocumentController_findLastParamsForContact,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminDocumentController_findLastParamsForContact = (options: {
  params: Params$AdminDocumentController_findLastParamsForContact['parameter'];
  config?: QueryOptions<
    Response$AdminDocumentController_findLastParamsForContact$Status$200[ResponseContentType$AdminDocumentController_findLastParamsForContact]
  >;
}) =>
  useApiClient<
    Params$AdminDocumentController_findLastParamsForContact,
    Response$AdminDocumentController_findLastParamsForContact$Status$200[ResponseContentType$AdminDocumentController_findLastParamsForContact]
  >({
    method: 'AdminDocumentController_findLastParamsForContact',
    params: { parameter: options.params },
    config: options.config,
  });
