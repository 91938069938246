import { Button, type TableColumnType, Typography } from 'antd';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';

type ColumnsProps = {
  adminRole?: Schemas.User['adminRole'];
  onApprove: (id: string, companyId: string) => void;
};

const { Text } = Typography;

const shouldShowApproveButton = (startDate: string) => {
  const today = dayjs();

  return (
    today.date() >= 25 ||
    (today.date() < 25 && today.month() !== dayjs(startDate).month())
  );
};

export const getColumns = (
  props: ColumnsProps
): TableColumnType<Schemas.BillingPeriodSummaryResponseDto>[] => [
  {
    key: 'company',
    dataIndex: 'company',
    title: 'Name',
    width: 300,
    render: (company) => (
      <Text strong>
        <Link to={`/companies/${company.id}`}>{company.name}</Link>
      </Text>
    ),
  },
  {
    key: 'totalNet',
    dataIndex: 'totalNet',
    title: 'w/o VAT',
    width: 200,
    render: (number) => <Text strong>{number}</Text>,
  },
  {
    key: 'total',
    dataIndex: 'total',
    title: 'with VAT',
    width: 200,
    render: (number) => <Text strong>{number}</Text>,
  },
  {
    key: 'actions',
    dataIndex: 'id',
    align: 'right',
    render: (id, row) =>
      props.adminRole === 'admin' &&
      shouldShowApproveButton(row.startDate) && (
        <Button
          type="primary"
          size="large"
          onClick={() => props.onApprove(id, row.company.id)}
        >
          Approve
        </Button>
      ),
  },
];
