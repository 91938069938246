import { FC, useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';

import { Flex, Typography } from 'antd';

import {
  PayslipsUploadButton,
  FilterConstructor,
  FilePreview,
  PayslipsList,
  PopupDelete,
} from '@entities';
import { ScrollbarBase } from '@components';
import { useCurrentCompanyId } from '@hooks';
import { useInfiniteScroll, usePagination } from '@hooks/useInfiniteScroll';
import { IconClose } from '@assets';

import { useAdminPayslipDocumentController_findAll  } from '@api-client/generated/AdminPayslipDocumentController/findAll'
import { useAdminPayslipDocumentController_delete  } from '@api-client/generated/AdminPayslipDocumentController/delete'
import { useAdminPayslipDocumentController_findOneById  } from '@api-client/generated/AdminPayslipDocumentController/findOneById'
import { useAdminPayslipDocumentController_updateOneById  } from '@api-client/generated/AdminPayslipDocumentController/updateOneById'

import { getSearchParams } from '@utils';

import { Schemas } from '@api-client/generated/types';

type PayslipDocument = Schemas.PayslipDocument;
type FilterOptions = Record<string, unknown>;

const { Title } = Typography;

const Payslips: FC = () => {
  const { search } = useLocation();
  const { id: payslipId } = useParams();
  const navigate = useNavigate();

  const companyId = useCurrentCompanyId();

  const searchParams = getSearchParams<FilterOptions>(search);

  const [isOpenPopupConfirm, setIsOpenPopupConfirm] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const [filterOptions, setFilterOptions] =
    useState<FilterOptions>(searchParams);

  const { metadata, incrementPage, hasNextPage, plainData, appendData } =
    usePagination<PayslipDocument>(JSON.stringify(filterOptions));

  const {
    data: documents,
    isFetching: isFetchingDocuments,
    refetch,
  } = useAdminPayslipDocumentController_findAll({
    params: {
      companyId: companyId!,
      page: metadata.currentPage,
      ...filterOptions,
    },
    config: {
      refetchOnWindowFocus: false,
    },
  });

  const { data: document } = useAdminPayslipDocumentController_findOneById({
    params: {
      id: payslipId!,
      companyId: companyId!,
    },
    config: {
      enabled: !!payslipId,
    },
  });

  const { mutate: updateDocument } = useAdminPayslipDocumentController_updateOneById();
  const { mutate: deleteDocument } = useAdminPayslipDocumentController_delete();

  const { sentryRef, rootRef } = useInfiniteScroll({
    isLoading: isFetchingDocuments,
    hasNextPage,
    onLoadMore: incrementPage,
  });

  useEffect(() => {
    if (documents) {
      appendData(documents);
    }
  }, [appendData, documents, filterOptions.term]);

  const handleSelectId = (id: string) => {
    setSelectedId(id);
    setIsOpenPopupConfirm(true);
  };

  const handleUnselectId = () => {
    setSelectedId(null);
    setIsOpenPopupConfirm(false);
  };

  const handleUpdateDocument = (id: string, name: string) => {
    updateDocument(
      {
        parameter: {
          companyId: companyId!,
          id,
        },
        requestBody: {
          name,
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleDeleteDocument = () => {
    if (selectedId) {
      deleteDocument(
        {
          parameter: {
            companyId: companyId!,
            id: selectedId,
          },
        },
        {
          onSuccess: () => {
            if (payslipId === selectedId) {
              navigate('/payslips');
            }

            setSelectedId(null);
            setIsOpenPopupConfirm(false);

            refetch();
          },
        }
      );
    }
  };

  return (
    <Flex gap={24} vertical>
      <Title>Payslips</Title>

      <PopupDelete
        open={isOpenPopupConfirm}
        title="Are you sure you want to delete this document?"
        description="Once deleted, you will not be able to restore it"
        confirmParams={{
          text: 'Delete',
        }}
        onRemove={handleDeleteDocument}
        onCancel={handleUnselectId}
      />

      <Flex justify="space-between" align="center">
        <FilterConstructor<PayslipDocument>
          controls={{
            left: [
              {
                type: 'search',
                formName: 'term',
                params: {
                  clearIcon: <IconClose width={16} height={16} />,
                  allowClear: true,
                },
              },
            ],
            right: [
              {
                type: 'custom-component',
                component: <PayslipsUploadButton onAfterUpload={refetch} />,
              },
            ],
          }}
          onChange={setFilterOptions}
          withSearchParams={false}
        />
      </Flex>

      <Flex gap={30} align="flex-start">
        <Flex flex="1">
          <ScrollbarBase
            ref={rootRef}
            height="calc(100vh - 325px)"
            withoutPadding
          >
            <PayslipsList
              documents={plainData}
              isLoading={isFetchingDocuments}
              onChangeName={handleUpdateDocument}
              onAfterUpload={refetch}
              onDelete={handleSelectId}
            />

            {hasNextPage && <div ref={sentryRef} />}
          </ScrollbarBase>
        </Flex>

        <FilePreview
          width="437px"
          title="Preview"
          file={document as PayslipDocument}
        />
      </Flex>
    </Flex>
  );
};

export default Payslips;
