import { RouteObject } from 'react-router-dom';

import { AccessDenied } from '@entities';
import { LayoutCompany, LayoutProtected, LayoutPublic } from '@layouts';
import {
  Accounts,
  Billing,
  ChatAndTasks,
  Companies,
  CompanyInfo,
  DocumentDetails,
  DocumentExpenseDetails,
  Documents,
  Incorporation,
  Legal,
  Login,
  MonthlyInvoices,
  Onboarding,
  Payslips,
  Processes,
  Settings,
  Taxes,
  TaxesDetails,
  TransactionDetails,
  Transactions,
} from '@pages';

type Route = Omit<RouteObject, 'children'> & {
  roles: '*' | string[];
  children?: Route[];
};

const chatAndTaskRoutes: Route[] = [
  {
    path: '/chat-tasks',
    element: <ChatAndTasks />,
    roles: ['admin', 'kyc', 'accountant'],
  },
  {
    path: '/chat-tasks/:companyId/chats',
    element: <ChatAndTasks />,
    roles: ['admin', 'kyc', 'accountant'],
  },
  {
    path: '/chat-tasks/:companyId/chats/:id',
    element: <ChatAndTasks />,
    roles: ['admin', 'kyc', 'accountant'],
  },
];

const allRoutes: Route[] = [
  {
    path: '/',
    element: <LayoutProtected />,
    roles: ['admin', 'kyc', 'accountant'],
    children: [
      ...chatAndTaskRoutes,
      {
        path: '/monthly-invoices',
        element: <MonthlyInvoices />,
        roles: ['admin', 'kyc', 'accountant'],
      },
      {
        path: '/settings',
        element: <Settings />,
        roles: ['admin'],
      },
      {
        path: '/companies',
        element: <LayoutCompany />,
        roles: ['admin', 'kyc', 'accountant'],
        children: [
          {
            path: '/companies',
            element: <Companies />,
            roles: ['admin', 'kyc', 'accountant'],
          },
          {
            path: '/companies/:companyId',
            roles: ['admin', 'kyc', 'accountant'],
            children: [
              {
                index: true,
                path: '/companies/:companyId',
                element: <Onboarding />,
                roles: ['admin', 'kyc'],
              },
              {
                path: '/companies/:companyId/info',
                element: <CompanyInfo />,
                roles: ['admin', 'kyc'],
              },
              {
                path: '/companies/:companyId/documents',
                element: <Documents />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/documents/bob50/:id',
                element: <DocumentExpenseDetails />,
                roles: ['admin', 'accountant', 'kyc'],
              },
              {
                path: '/companies/:companyId/documents/:id',
                element: <DocumentDetails />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/transactions',
                element: <Transactions />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/transactions/:id',
                element: <TransactionDetails />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/accounts',
                element: <Accounts />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/incorporation',
                element: <Incorporation />,
                roles: ['admin', 'kyc'],
              },
              {
                path: '/companies/:companyId/legal',
                element: <Legal />,
                roles: ['admin', 'kyc', 'accountant'],
              },
              {
                path: '/companies/:companyId/taxes',
                element: <Taxes />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/taxes/create',
                element: <TaxesDetails type="create" />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/taxes/:id',
                element: <TaxesDetails type="view" />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/billing',
                element: <Billing />,
                roles: ['admin'],
              },
              {
                path: '/companies/:companyId/billing/:billingId',
                element: <Billing />,
                roles: ['admin'],
              },
              {
                path: '/companies/:companyId/one-time-billing/:oneTimeBillingId',
                element: <Billing />,
                roles: ['admin'],
              },
              {
                path: '/companies/:companyId/processes',
                element: <Processes />,
                roles: ['admin'],
              },
              {
                path: '/companies/:companyId/payslips',
                element: <Payslips />,
                roles: ['admin', 'accountant'],
              },
              {
                path: '/companies/:companyId/payslips/:id',
                element: <Payslips />,
                roles: ['admin', 'accountant'],
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/',
    element: <LayoutPublic />,
    roles: '*',
    children: [
      {
        index: true,
        path: '/login',
        roles: '*',
        element: <Login />,
      },
    ],
  },
];

export const getRoutes = (
  role?: string,
  routes: Route[] = allRoutes
): Route[] =>
  routes.map((route) => ({
    ...route,
    children: route.children?.map((child) => {
      let element = child.element;
      if ((role && route.roles.includes(role)) || route.roles === '*') {
        element = child.element;
      } else {
        element = <AccessDenied />;
      }

      return {
        ...child,
        element,
        children: child.children ? getRoutes(role, child.children) : undefined,
      };
    }),
  }));
