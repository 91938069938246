import { message } from 'antd';
import { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminDocumentController_findOneById } from '@api-client/generated/AdminDocumentController/findOneById';
import { useAdminDocumentController_updateOneById } from '@api-client/generated/AdminDocumentController/updateOneById';
import { CopyButton, Loader } from '@components';
import {
  DetailsHeader,
  DocumentExpenseCard,
  DocumentExpenseReport,
  FilePreview,
} from '@entities';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';

const DocumentExpenseDetails: FC = () => {
  const { id: documentId } = useParams();
  const navigate = useNavigate();

  const companyId = useCurrentCompanyId();

  const [isVisibleReportError, setIsVisibleReportError] = useState(false);

  const {
    data: document,
    isFetching: isLoading,
    refetch,
  } = useAdminDocumentController_findOneById({
    params: {
      id: documentId!,
      companyId: companyId!,
    },
    config: {
      refetchOnWindowFocus: false,
    },
  });

  const { mutate: updateDocument } = useAdminDocumentController_updateOneById();

  const handleSendReport = (errorCode: string) =>
    updateDocument(
      {
        parameter: {
          id: documentId!,
          companyId: companyId!,
        },
        requestBody: {
          adminStatus: 'requested_changes',
          errorCode,
        },
      },
      {
        onSuccess: () => {
          message.success('The error report has been sent successfully');
        },
      }
    );

  if (!document || isLoading) {
    return <Loader />;
  }

  return (
    <>
      <DetailsHeader
        goBackText="Go to all documents"
        onNavigate={() =>
          navigate(`/companies/${companyId}/documents`, { replace: true })
        }
      >
        <CopyButton
          tooltipPlacement="bottom"
          icon="chain"
          value={location.href}
          type="link"
        />
      </DetailsHeader>

      <DocumentExpenseReport
        open={isVisibleReportError}
        onRequest={handleSendReport}
        onCancel={() => setIsVisibleReportError(false)}
      />

      <S.Inner gap={30} align="flex-start">
        <FilePreview
          // headerContent={
          //   <Flex justify="flex-end">
          //     <S.ReportLink
          //       disabled={document.adminStatus === 'processed'}
          //       onClick={() => setIsVisibleReportError(true)}
          //     >
          //       <IconWarningSquare /> Report error
          //     </S.ReportLink>
          //   </Flex>
          // }
          width="542px"
          height="697px"
          file={document}
        />

        <DocumentExpenseCard document={document} onAfterUpdate={refetch} />
      </S.Inner>
    </>
  );
};

export default DocumentExpenseDetails;
