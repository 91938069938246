import { Card, Flex, Table, Typography, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';

import { useAdminBillingPeriodController_approve } from '@api-client/generated/AdminBillingPeriodController/approve';
import { useAdminBillingPeriodController_findAllUnapproved } from '@api-client/generated/AdminBillingPeriodController/findAllUnapproved';
import { Schemas } from '@api-client/generated/types';
import { SearchControl } from '@entities';
import { useAccount } from '@hooks';

import { getColumns } from './columns';
import { getGroupedInvoicesByStartDate } from './helpers';
import * as S from './styled';

type Invoice = Schemas.BillingPeriodSummaryResponseDto;

const { Title } = Typography;

const MonthlyInvoices = () => {
  const { role } = useAccount();

  const [invoicesList, setInvoicesList] = useState<Invoice[]>([]);
  const [searchValue, setSearchValue] = useState('');

  const { data: invoices } =
    useAdminBillingPeriodController_findAllUnapproved();

  const { mutate: approveInvoice } = useAdminBillingPeriodController_approve();

  const columns = getColumns({
    onApprove: (id, companyId) =>
      approveInvoice(
        { parameter: { id, companyId } },
        {
          onSuccess: () => {
            message.success('The invoice has been successfully approved');

            setInvoicesList((prevState) =>
              prevState.filter((invoice) => invoice.id !== id)
            );
          },
        }
      ),
    adminRole: role,
  });

  const filteredInvoices = useMemo(
    () =>
      searchValue.trim()
        ? invoicesList.filter((invoice) =>
            invoice.company.name
              ?.toLowerCase()
              .trim()
              .includes(searchValue.toLowerCase().trim())
          )
        : invoicesList,
    [searchValue, invoicesList]
  );

  useEffect(() => {
    if (invoices) {
      setInvoicesList(invoices);
    }
  }, [invoices]);

  return (
    <Flex gap={24} vertical>
      <Title>Monthly Invoices</Title>

      <SearchControl
        placeholder="Search invoices"
        onChange={(e) => setSearchValue(e.target.value)}
      />

      <S.TableContainer>
        <Flex gap={24} vertical>
          {getGroupedInvoicesByStartDate(filteredInvoices).map((group) => (
            <Card key={group.date} title={group.date}>
              <Table
                rowKey={({ id }) => id}
                dataSource={group.invoices}
                columns={columns}
                pagination={false}
                scroll={{ x: 900 }}
              />
            </Card>
          ))}
        </Flex>
      </S.TableContainer>
    </Flex>
  );
};

export default MonthlyInvoices;
