import { List, type ListProps } from 'antd';
import { ReactNode } from 'react';

import { Schemas } from '@api-client/generated/types';
import { getAddressLine } from '@utils';

import * as S from './styled';

type BobContact = Schemas.BobContact;

type ContactsSimpleListProps = {
  emptyText?: ReactNode;
  selectedId?: string | null;
  dataSource?: Schemas.BobContact[];
  onSelect: (contact: BobContact) => void;
} & ListProps<BobContact>;

const ContactsSimpleList = ({
  emptyText,
  selectedId,
  dataSource,
  onSelect,
  ...props
}: ContactsSimpleListProps) => (
  <List
    dataSource={dataSource}
    renderItem={(item) => (
      <S.Contact
        gap={4}
        selected={selectedId === item.id}
        onClick={() => onSelect(item)}
        vertical
      >
        <S.ContactName>{item.name}</S.ContactName>
        <div>ID: {item.bobId || '-'}</div>
        <div>VAT ID: {item.vatId || '-'}</div>
        <div>{getAddressLine(item.address)}</div>
      </S.Contact>
    )}
    locale={{
      emptyText: emptyText ? <S.Empty>{emptyText}</S.Empty> : <div />,
    }}
    {...props}
  />
);

export default ContactsSimpleList;
