import { Flex } from 'antd';
import { FC } from 'react';

import { Schemas } from '@api-client/generated/types';
import { IconEditUnderline } from '@assets';
import { CompanyDetailsInfoCell } from '@entities';

import * as S from './styled';

type CompanyDetailsPersonProps = {
  details?: Schemas.ContactPersonDto;
  onEdit: () => void;
};

const CompanyDetailsPerson: FC<CompanyDetailsPersonProps> = ({
  details,
  onEdit,
}) => (
  <S.Container>
    <S.Edit onClick={onEdit}>
      <IconEditUnderline width={16} height={16} /> Edit
    </S.Edit>

    <Flex gap={16} vertical>
      <CompanyDetailsInfoCell
        label="Name"
        value={`${details?.firstName} ${details?.lastName}`}
        isVisible={details?.firstName || details?.lastName}
        copyText={`${details?.firstName} ${details?.lastName}`}
      />

      <CompanyDetailsInfoCell
        label="Contact details"
        value={
          <>
            <span>{details?.email}</span>
            <span>{details?.phone}</span>
          </>
        }
        isVisible={details?.email || details?.phone}
        copyText={`${details?.email} ${details?.phone}`}
      />
    </Flex>
  </S.Container>
);

export default CompanyDetailsPerson;
