import { List } from 'antd';
import { useParams } from 'react-router-dom';

import { Schemas } from '@api-client/generated/types';

import BillingListEmpty from '../BillingListEmpty';
import BillingListItem from '../BillingListItem';

type BillingListPeriods = {
  type: 'period';
  dataSource: Schemas.BillingPeriod[];
  onAdd: VoidFunction;
};

type BillingListOneTimeInvoices = {
  type: 'one-time';
  isDisabled?: boolean;
  dataSource: Schemas.BillingDocument[];
  onAdd: VoidFunction;
};

type BillingListProps = BillingListPeriods | BillingListOneTimeInvoices;

const BillingList = (props: BillingListProps) => {
  const { id: billingId } = useParams();

  if (!props.dataSource.length) {
    return (
      <BillingListEmpty
        isDisabled={props.type === 'one-time' && props.isDisabled}
        type={props.type}
        onAdd={props.onAdd}
      />
    );
  }

  switch (props.type) {
    case 'period':
      return (
        <List
          dataSource={props.dataSource}
          renderItem={(item) => (
            <BillingListItem
              type={props.type}
              selectedId={billingId!}
              details={item}
            />
          )}
        />
      );
    case 'one-time':
      return (
        <List
          dataSource={props.dataSource}
          renderItem={(item) => (
            <BillingListItem
              type={props.type}
              selectedId={billingId!}
              details={item}
            />
          )}
        />
      );
  }
};

export default BillingList;
