import { Button, Flex, Form, Modal, type ModalProps, Row } from 'antd';
import { message } from 'antd';
import { FC } from 'react';

import { useAdminBobContactController_create } from '@api-client/generated/AdminBobContactController/create';
import { Schemas } from '@api-client/generated/types';
import { DEFAULT_COUNTRY_CODE } from '@constants';
import { ContactsWrapperFormControl } from '@entities';
import { useCurrentCompanyId } from '@hooks';
import { countries } from '@utils/countries';

import * as S from './styled';

type ContactActionProps = {
  onCancel: () => void;
  onAfterCreate?: (contact: Schemas.BobContact) => void;
} & ModalProps;

const ContactAction: FC<ContactActionProps> = ({
  open,
  onAfterCreate,
  onCancel,
  ...rest
}) => {
  const companyId = useCurrentCompanyId();

  const [form] = Form.useForm();

  const { mutate: createContact, isPending: loading } =
    useAdminBobContactController_create();

  const handleCancel = () => {
    form.resetFields();
    onCancel();
  };

  const handleSubmit = (values: Schemas.AdminBobContactDto) =>
    createContact(
      {
        parameter: {
          companyId,
        },
        requestBody: values,
      },
      {
        onSuccess: (response) => {
          message.success('New contact successfully created');

          onAfterCreate?.(response);
          handleCancel();
        },
      }
    );

  return (
    <Modal
      width={575}
      open={open}
      title="Add new contact"
      footer={null}
      onCancel={handleCancel}
      destroyOnClose
      centered
      {...rest}
    >
      <Form
        form={form}
        onFinish={handleSubmit}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          address: {
            countryCode: DEFAULT_COUNTRY_CODE,
          },
        }}
      >
        <S.Card>
          <ContactsWrapperFormControl
            type="input"
            span={24}
            form={{
              label: 'Contact name',
              name: 'name',
              rules: [{ required: true }],
            }}
          />

          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'VAT ID',
                name: 'vatId',
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'BOB50 ID',
                name: 'bobId',
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'IBAN',
                name: 'iban',
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'BIC',
                name: 'bic',
                rules: [{ required: true }],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={10}
              form={{
                label: 'Street',
                name: ['address', 'addressLine'],
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'Number',
                name: ['address', 'houseNumber'],
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={7}
              form={{
                label: 'Postal code',
                name: ['address', 'postalCode'],
                rules: [{ required: true }],
              }}
            />

            <ContactsWrapperFormControl
              type="select"
              span={10}
              form={{
                label: 'Country',
                name: ['address', 'countryCode'],
                rules: [{ required: true }],
              }}
              control={{
                options: countries.map((country) => ({
                  label: country.name,
                  value: country.value,
                })),
                virtual: true,
                optionFilterProp: 'label',
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={14}
              form={{
                label: 'City',
                name: ['address', 'city'],
                rules: [{ required: true }],
              }}
            />
          </Row>
        </S.Card>

        <S.Card>
          <Row gutter={[12, 0]}>
            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'Email (optional)',
                name: 'email',
              }}
            />

            <ContactsWrapperFormControl
              type="input"
              span={12}
              form={{
                label: 'Phone number (optional)',
                name: 'phone',
              }}
            />
          </Row>
        </S.Card>

        <S.Submit>
          <Form.Item noStyle>
            <Flex justify="flex-end" gap={12}>
              <Button onClick={handleCancel} size="large">
                Cancel
              </Button>

              <Button
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
              >
                Add
              </Button>
            </Flex>
          </Form.Item>
        </S.Submit>
      </Form>
    </Modal>
  );
};

export default ContactAction;
