import { FC } from 'react';

import { Flex } from 'antd';

import {
  DocumentExpenseForm,
  DocumentExpenseStatus,
  StatusTag,
} from '@entities';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';

type DocumentExpenseCardProps = {
  document: Schemas.Document;
  onAfterUpdate: () => void;
};

const DocumentExpenseCard: FC<DocumentExpenseCardProps> = ({
  document,
  onAfterUpdate,
}) => {
  const isProcessing = document.adminStatus === 'processing';
  const isBooked = document.adminStatus === 'processed';

  return (
    <S.Card>
      {(isProcessing || isBooked) && (
        <DocumentExpenseStatus status={document.adminStatus} bobStatus={document.bobStatus} />
      )}

      <S.Inner gap={18} vertical>
        <Flex align="center" justify="space-between">
          <S.Title>Expense Details</S.Title>

          {!isBooked && !isProcessing && (
            <StatusTag status="waiting" isMinWidthAuto />
          )}
        </Flex>

        <DocumentExpenseForm
          document={document}
          onAfterUpdate={onAfterUpdate}
        />
      </S.Inner>
    </S.Card>
  );
};

export default DocumentExpenseCard;
