import { Flex } from 'antd';
import { FC, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { IconChat, IconCompanies, IconSettings } from '@assets';
import { Logotype } from '@entities';
import { useAccount } from '@hooks';
import { filteredMenu } from '@utils';

import * as S from './styled';

const Sidebar: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { role } = useAccount();

  const menuList = [
    {
      name: 'Companies',
      to: '/companies',
      icon: <IconCompanies />,
    },
    {
      name: 'Monthly Invoices',
      to: '/monthly-invoices',
      icon: <IconCompanies />,
    },
    {
      name: 'Chats & Tasks',
      to: '/chat-tasks',
      icon: <IconChat />,
    },
  ];

  const menuBottom = [
    {
      to: '/settings',
      name: 'settings',
      icon: <IconSettings />,
    },
  ];

  const filteredMenuList = filteredMenu(menuList, role);

  const filteredMenuBottomList = filteredMenu(menuBottom, role);

  useEffect(
    () => {
      if (pathname === '/') {
        navigate('/companies');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pathname]
  );

  return (
    <S.Sidebar width={105}>
      <Flex vertical>
        <Logotype />

        <S.Menu>
          {filteredMenuList.map((item) => (
            <S.MenuLink key={item.to} to={item.to}>
              {item.icon}
              {item.name}
            </S.MenuLink>
          ))}
        </S.Menu>
      </Flex>

      <S.Menu>
        {filteredMenuBottomList.map((item) => (
          <S.MenuLink key={item.to} to={item.to}>
            {item.icon}
            {item.name}
          </S.MenuLink>
        ))}
      </S.Menu>
    </S.Sidebar>
  );
};

export default Sidebar;
