import styled from '@emotion/styled';

export const TableContainer = styled.div`
  max-width: 1000px;

  .ui-card {
    .ui-card-body {
      padding-block: 12px;
    }
  }

  .ui-table {
    .ui-table-thead {
      .ui-table-cell {
        padding-block: 0;

        &:first-of-type {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }
    }

    .ui-table-tbody {
      .ui-table-row {
        &:last-child {
          .ui-table-cell {
            border-bottom: 0;
          }
        }

        .ui-table-cell {
          &:first-of-type {
            padding-left: 0;
          }

          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
`;
