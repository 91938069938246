import { Button, DatePicker, Flex, Form, Input, Modal, Typography } from 'antd';
import { Dayjs } from 'dayjs';
import React from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { useAdminBillingDocumentController_createOneTimeInvoice } from '@api-client/generated/AdminBillingDocumentController/createOneTimeInvoice';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';

type FormValues = {
  number: string;
  issueDate: Dayjs;
  dueDate: Dayjs;
  items: {
    name: string;
    description: string;
    unitPrice: number;
    totalPrice: number;
  }[];
};

type ModalBillingOneTimeInvoiceProps = {
  isOpen: boolean;
  onCancel: () => void;
  onAfterCreate: () => void;
};

const ModalBillingOneTimeInvoice = (props: ModalBillingOneTimeInvoiceProps) => {
  const [form] = Form.useForm<FormValues>();
  const companyId = useCurrentCompanyId();

  const { mutate: createInvoice, isPending: isCreateInvoicePending } =
    useAdminBillingDocumentController_createOneTimeInvoice();

  const updateTotalPrice =
    (name: number) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value;

      const items = form.getFieldValue('items') || [];
      items[name].price = +value;
      items[name].totalPrice = Math.round(+value * 1.17 * 100) / 100;

      form.setFieldsValue({
        items,
      });
    };

  const handleSubmit = (values: FormValues) => {
    createInvoice(
      {
        parameter: {
          companyId: companyId,
        },
        requestBody: {
          documentMetadata: {
            amount: null,
            currencyCode: null,
            issueDate: values.issueDate.format('YYYY-MM-DD'),
            dueDate: values.dueDate.format('YYYY-MM-DD'),
            number: values.number,
            items: values.items.map((item) => ({
              name: item.name,
              description: item.description,
              amount: null,
              taxRate: 0.17,
              unitPrice: item.unitPrice,
              totalPrice: item.totalPrice,
            })),
          },
        },
      },
      {
        onSuccess: () => {
          props.onAfterCreate();
          form.resetFields();
          props.onCancel();
        },
      }
    );
  };

  return (
    <Modal
      open={props.isOpen}
      title="One time invoice"
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ items: [{}] }}
        onFinish={handleSubmit}
      >
        <Form.Item
          label="Invoice number"
          name="number"
          rules={[{ required: true }]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Issued date"
          name="issueDate"
          rules={[{ required: true }]}
        >
          <DatePicker size="large" />
        </Form.Item>
        <Form.Item label="Due date" name="dueDate" rules={[{ required: true }]}>
          <DatePicker size="large" />
        </Form.Item>
        <Typography.Title level={3}>Items</Typography.Title>
        <Form.List name="items">
          {(fields, { add, remove }) => (
            <Flex vertical gap={8}>
              {fields.map((field, index) => (
                <Flex key={field.key} vertical>
                  <Flex justify="space-between">
                    <Typography.Title level={5}>
                      Item {index + 1}
                    </Typography.Title>
                    {fields.length > 1 && (
                      <Button
                        type="text"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(index)}
                        danger
                      />
                    )}
                  </Flex>
                  <Form.Item
                    name={[field.name, 'name']}
                    label="Name"
                    rules={[{ required: true }]}
                  >
                    <Input size="large" />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'description']}
                    label="Description (optional)"
                  >
                    <Input.TextArea />
                  </Form.Item>
                  <Form.Item
                    name={[field.name, 'unitPrice']}
                    label="Price before VAT (17%)"
                    rules={[{ required: true }]}
                  >
                    <Input
                      type="number"
                      size="large"
                      onChange={updateTotalPrice(field.name)}
                    />
                  </Form.Item>
                  <Form.Item name={[field.name, 'totalPrice']} label="Price">
                    <Input type="number" size="large" disabled />
                  </Form.Item>
                </Flex>
              ))}
              <Button
                type="link"
                onClick={() => {
                  add();
                }}
              >
                Add item
              </Button>
            </Flex>
          )}
        </Form.List>
        <S.Footer gap={16} justify="flex-end">
          <Button onClick={props.onCancel} size="large">
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={isCreateInvoicePending}
          >
            Submit
          </Button>
        </S.Footer>
      </Form>
    </Modal>
  );
};

export default ModalBillingOneTimeInvoice;
