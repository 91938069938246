import { Button, Form, Input, Modal, Select } from 'antd';
import { useEffect } from 'react';

import * as S from './styled';

type Company = {
  companyNames: string[];
  companyType: string;
  companyActivity: string;
};

type FormValues = {
  companyNames: string;
  companyType: string;
  companyActivity: string;
};

type CompanyOnboardingDetailsEditProps = {
  isOpen: boolean;
  company: Company;
  onSubmit: (values: Company) => void;
  onCancel: () => void;
};

const CompanyOnboardingDetailsEdit = (props: CompanyOnboardingDetailsEditProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
    form.setFieldsValue({
      ...props.company,
      companyNames: props.company.companyNames.join(', '),
    });
  }, [props.company, form, props.isOpen]);

  const handleSubmit = (values: FormValues) => {
    props.onSubmit({
      ...values,
      companyNames: values.companyNames.split(',').map((name) => name.trim()),
    });
    props.onCancel();
  };

  return (
    <Modal
      open={props.isOpen}
      footer={null}
      onCancel={props.onCancel}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <Form.Item label="Company Name(s)" name="companyNames">
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="companyType">
          <Select
            options={[
              { label: 'sarl-s', value: 'sarl-s' },
              {
                label: 'sarl',
                value: 'sarl',
              },
            ]}
          />
        </Form.Item>
        <Form.Item label="Company activity" name="companyActivity">
          <Input />
        </Form.Item>
        <S.Footer gap={8} justify="flex-end">
          <Button size="large" htmlType="button" onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
          >
            Update
          </Button>
        </S.Footer>
      </Form>
    </Modal>
  );
};

export default CompanyOnboardingDetailsEdit;
