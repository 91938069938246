import { Button, Popover, Typography } from 'antd';

import * as S from './styled';

type BillingEmptyProps = {
  type: 'period' | 'one-time';
  onAdd: VoidFunction;
  isDisabled?: boolean;
};

const BillingEmpty = (props: BillingEmptyProps) => (
  <S.Container>
    <S.Title>There are no billings yet</S.Title>

    <S.Description>
      Use billings to generate invoices and other payment documents
    </S.Description>

    <Popover
      content={
        props.isDisabled && (
          <>
            <Typography.Paragraph>
              Fill the following fields on Company Info tab:
            </Typography.Paragraph>
            <ul>
              <li>Company name</li>
              <li>Address</li>
            </ul>
          </>
        )
      }
    >
      <Button disabled={props.isDisabled} onClick={props.onAdd} size="large" ghost>
        {props.type === 'period' && 'Add billing period'}
        {props.type === 'one-time' && 'Add one-time invoice'}
      </Button>
    </Popover>
  </S.Container>
);

export default BillingEmpty;
