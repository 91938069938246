import { Button, Flex } from 'antd';
import { format } from 'date-fns';
import { FC, useState } from 'react';

import { useAdminAccountsController_syncConnection } from '@api-client/generated/AdminAccountsController/syncConnection';
import { Schemas } from '@api-client/generated/types';
import { Amount } from '@entities';
import { useCurrentCompanyId } from '@hooks';

import * as S from './styled';

type BalanceAmount = {
  balanceAmount: {
    amount: string;
    currency: string;
  };
};

type Account = Schemas.Account & {
  requestedSync: boolean;
  balances: BalanceAmount[];
};

type AccountItemProps = {
  account: Account;
};

type Balance = Schemas.Balance;

const AccountItem: FC<AccountItemProps> = (record) => {
  const [account, setAccount] = useState(record.account);
  const companyId = useCurrentCompanyId();

  const { mutate: syncAccount } = useAdminAccountsController_syncConnection();

  const balance =
    account.balances.find(
      (balance: Balance) => balance.balanceType === 'calculated'
    ) || account.balances[0];

  const processSync = (account: Account) => {
    const updatedAccount = { ...account, requestedSync: true };
    setAccount(updatedAccount);

    syncAccount({
      parameter: {
        id: record.account.id,
        companyId: companyId!,
      },
    });
  };

  return (
    <S.Wrapper>
      <Flex align="center" gap={16}>
        <S.Name>{account.accountName}</S.Name>

        {account.balances.length ? (
          <S.Cell>
            <span>Balance</span>
            <strong>
              <Amount
                amount={Number(balance.balanceAmount.amount)}
                currencyCode={balance.balanceAmount.currency}
                withoutType
              />
            </strong>
          </S.Cell>
        ) : null}

        {account.lastSyncedAt && (
          <S.Cell>
            <span>Last synced on</span>
            <strong>{format(account.lastSyncedAt, 'dd MMM yyyy')}</strong>
          </S.Cell>
        )}

        {account.status ? (
          <S.Cell>
            <Button
              type="text"
              size="large"
              disabled={account.requestedSync}
              onClick={() => processSync(account)}
            >
              {account.requestedSync ? 'Requested' : 'Sync now'}
            </Button>
          </S.Cell>
        ) : null}
      </Flex>
    </S.Wrapper>
  );
};

export default AccountItem;
