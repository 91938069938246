//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminBillingDocumentController_createOneTimeInvoice,
  Response$AdminBillingDocumentController_createOneTimeInvoice$Status$201,
  ResponseContentType$AdminBillingDocumentController_createOneTimeInvoice,
} from '@api-client/generated/client';
import { useApiClientMutation } from '@hooks/useApiClientMutation';

export const useAdminBillingDocumentController_createOneTimeInvoice = () =>
  useApiClientMutation<
    Params$AdminBillingDocumentController_createOneTimeInvoice,
    Response$AdminBillingDocumentController_createOneTimeInvoice$Status$201[ResponseContentType$AdminBillingDocumentController_createOneTimeInvoice]
  >({ method: 'AdminBillingDocumentController_createOneTimeInvoice' });
