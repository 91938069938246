import { FC } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { List, Flex } from 'antd';

import PayslipsUploadButton from '../PayslipsUploadButton';
import PayslipsListItem from '../PayslipsListItem';

import { Schemas } from '@api-client/generated/types';

import * as S from './styled';
import { useCurrentCompanyId } from '@hooks';

type PayslipsListProps = {
  documents: Schemas.PayslipDocument[];
  isLoading: boolean;
  onAfterUpload: () => void;
  onChangeName: (id: string, name: string) => void;
  onDelete: (id: string) => void;
};

const PayslipsList: FC<PayslipsListProps> = ({
  documents,
  isLoading,
  onAfterUpload,
  onChangeName,
  onDelete,
}) => {
  const currentCompanyId = useCurrentCompanyId();
  const { id: payslipId } = useParams();
  const navigate = useNavigate();

  const handleNavigateToCompany = (id: string) => {
    if (payslipId !== id) {
      navigate(`/companies/${currentCompanyId}/payslips/${id}`);
    }
  };

  return (
    <List
      dataSource={documents}
      renderItem={(item) => (
        <PayslipsListItem
          name={item.name}
          url={item.url}
          isSelected={item.id === payslipId}
          onChangeName={(name) => onChangeName(item.id, name)}
          onDelete={() => onDelete(item.id)}
          onClick={() => handleNavigateToCompany(item.id)}
        />
      )}
      loading={isLoading}
      locale={{
        emptyText: (
          <S.Empty gap={32} align="center" justify="center" vertical>
            <Flex gap={8} justify="center" vertical>
              <span>There are no company documents yet</span>
              <span>You need to add your documents to display them here</span>
            </Flex>

            <PayslipsUploadButton onAfterUpload={onAfterUpload} />
          </S.Empty>
        ),
      }}
    />
  );
};

export default PayslipsList;
