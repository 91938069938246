import { Button, Flex, Form, Row, Spin, message } from 'antd';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import sum from 'lodash/sum';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';

import { useAdminBobAccountController_findAll } from '@api-client/generated/AdminBobAccountController/findAll';
import { useAdminBobDaybookController_findAll } from '@api-client/generated/AdminBobDaybookController/findAll';
import { useAdminBobVATController_findAll } from '@api-client/generated/AdminBobVATController/findAll';
import { useAdminCurrencyExchangeRateController_getRate } from '@api-client/generated/AdminCurrencyExchangeRateController/getRate';
import { useAdminDocumentController_findLastParamsForContact } from '@api-client/generated/AdminDocumentController/findLastParamsForContact';
import { useAdminDocumentController_updateOneById } from '@api-client/generated/AdminDocumentController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { IconLock, IconTrash } from '@assets';
import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIMEOUT_FOR_DEBOUNCE,
  currencyCodes,
} from '@constants';
import {
  ContactsPopupList,
  DocumentExpenseAmountStatus,
  DocumentExpenseWrapperFormControl,
} from '@entities';
import { useCurrentCompanyId, useCurrentCompany } from '@hooks';

import * as S from './styled';

type BookkeepingDocumentMetadataDto =
  Partial<Schemas.BookkeepingDocumentMetadataDto> & {
    bobContactId?: string;
    referenceId?: string;
  };

type BookingDocumentMetadata = Schemas.BookingDocumentMetadata;

type DocumentExpenseFormProps = {
  document: Schemas.Document;
  onAfterUpdate: () => void;
};

const showUpdatedMessage = () =>
  message.success('Document information has been successfully updated');

const getStatusByAmount = (
  values: BookkeepingDocumentMetadataDto | BookingDocumentMetadata = {}
) => {
  const items = values.items || [];
  const hasTotalPriceZero = items.some((item) => item?.totalPrice === 0);
  const sumTotalPrice = sum(items.map((item) => item?.totalPrice));

  if (hasTotalPriceZero) return false;

  return items.length ? values?.amount === sumTotalPrice : true;
};

const DocumentExpenseForm: FC<DocumentExpenseFormProps> = ({
  document,
  onAfterUpdate,
}) => {
  const { id: documentId } = useParams();
  const companyId = useCurrentCompanyId();
  const { data: company } = useCurrentCompany();
  const hasBobReferenceId = company?.bobReferenceId;

  const [form] = Form.useForm();
  const values = Form.useWatch([], form);

  const isProcessing = document.adminStatus === 'processing';
  const isBooked = document.adminStatus === 'processed';
  const isReady = document.adminStatus === 'ready';
  const hasBookingError = document.bobStatus === 'failed';

  const hasDisabled = isBooked || isProcessing;

  const reqParameters = {
    id: documentId!,
    companyId: companyId!,
  };

  const [paramsForContact, setParamsForContact] =
    useState<Schemas.BobLastDataForContactResponseDto>();
  const [submittable, setSubmittable] = useState(false);
  const [formValues, setFormValues] = useState<
    BookkeepingDocumentMetadataDto | BookingDocumentMetadata
  >();
  const [selectedContact, setSelectedContact] =
    useState<Schemas.BobContact | null>(null);
  const [unlockedDocumentId, setUnlockedDocumentId] = useState(false);

  const {
    data: currencyExchangeRate,
    isFetching: isLoadingCurrencyExchangeRate,
  } = useAdminCurrencyExchangeRateController_getRate({
    params: {
      from: DEFAULT_CURRENCY_CODE,
      to: form.getFieldValue('currency'),
      date: dayjs(form.getFieldValue('issueDate')).format('YYYY-MM-DD'),
    },
    config: {
      refetchOnWindowFocus: false,
      enabled: !!(
        form.getFieldValue('currency') && form.getFieldValue('issueDate')
      ),
    },
  });

  const { data: daybooks, isPending: isLoadingDaybooks } =
    useAdminBobDaybookController_findAll({
      params: {
        companyId,
      },
    });

  const { data: accounts, isPending: isLoadingAccounts } =
    useAdminBobAccountController_findAll({
      params: {
        companyId,
      },
    });

  const { data: vats, isPending: isLoadingVats } =
    useAdminBobVATController_findAll({
      params: {
        companyId,
      },
    });

  const { refetch, isFetching: isLoadingParamsForContact } =
    useAdminDocumentController_findLastParamsForContact({
      params: {
        companyId: companyId!,
        contactId: selectedContact?.id as string,
      },
      config: {
        enabled: false,
      },
    });

  const { mutate: updateDocument } = useAdminDocumentController_updateOneById();

  useEffect(() => {
    if (paramsForContact === undefined) return;

    const formItems = form.getFieldValue('items');

    if (isEmpty(paramsForContact)) {
      const bodyParams = {
        bobContactId: form.getFieldValue('bobContactId'),
        daybookId: null,
        items: formItems.map((item: Schemas.BookingItem) => ({
          ...item,
          accountCode: null,
          vatCode: null,
        })),
      };

      form.setFieldsValue(bodyParams);
      handleUpdate(bodyParams);

      return;
    }

    const bodyParams = {
      bobContactId: form.getFieldValue('bobContactId'),
      daybookId: paramsForContact.daybookId,
      items:
        formItems.length === 1
          ? formItems.map((item: Schemas.BookingItem) => ({
              ...item,
              accountCode: paramsForContact.accountCode,
              vatCode: paramsForContact.vatCode,
            }))
          : formItems,
    };

    form.setFieldsValue(bodyParams);
    handleUpdate(bodyParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsForContact]);

  useEffect(
    () => {
      if (document) {
        form.setFieldsValue({
          ...document.bookeepingMetadata,
          bobContactId: document.bobContact?.id,
          referenceId: document.referenceId,
          issueDate:
            document.bookeepingMetadata.issueDate &&
            dayjs(document.bookeepingMetadata.issueDate),
          dueDate:
            document.bookeepingMetadata.dueDate &&
            dayjs(document.bookeepingMetadata.dueDate),
        });

        setSelectedContact(document.bobContact);
        setFormValues(document.bookeepingMetadata);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document]
  );

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  useEffect(
    () => () => {
      form.resetFields();
      setParamsForContact(undefined);
    },
    [form]
  );

  const handleUpdateWithDebounce = useDebouncedCallback(
    (values) => handleUpdate(values),
    DEFAULT_TIMEOUT_FOR_DEBOUNCE
  );

  const handleUpdate = (values: BookkeepingDocumentMetadataDto) => {
    const { bobContactId, referenceId, ...body } = values;

    let dueDate = null;
    let issueDate = null;

    if (body?.dueDate) {
      dueDate = body?.dueDate;
    }

    if (body?.issueDate) {
      issueDate = body?.issueDate;
    }

    if (
      body?.dueDate === undefined &&
      body?.dueDate !== null &&
      formValues?.dueDate
    ) {
      dueDate = formValues?.dueDate;
    }

    if (
      body?.issueDate === undefined &&
      body?.issueDate !== null &&
      formValues?.issueDate
    ) {
      issueDate = formValues?.issueDate;
    }

    updateDocument(
      {
        parameter: reqParameters,
        requestBody: {
          bobContactId: bobContactId,
          referenceId: referenceId,
          bookeepingMetadata: {
            ...omit(form.getFieldsValue(), ['bobContactId', 'referenceId']),
            ...body,
            dueDate:
              dueDate !== null ? dayjs(dueDate).format('YYYY-MM-DD') : null,
            issueDate:
              issueDate !== null ? dayjs(issueDate).format('YYYY-MM-DD') : null,
          } as Schemas.BookkeepingDocumentMetadataDto,
        },
      },
      { onSuccess: () => showUpdatedMessage() }
    );
  };

  const handleSubmit = () =>
    updateDocument(
      {
        parameter: reqParameters,
        requestBody: {
          adminStatus: 'processing',
          bobStatus: 'pending',
        },
      },
      {
        onSuccess: () => {
          showUpdatedMessage();
          setUnlockedDocumentId(false);
          onAfterUpdate();
        },
      }
    );

  const handleUpdateList = (
    field: string,
    name: number,
    value: string | number
  ) =>
    ((form.getFieldValue('items') || []) as Schemas.BookingItemDto[]).map(
      (item, index) => (name === index ? { ...item, [field]: value } : item)
    );

  const handleUpdateContact = async (contact: Schemas.BobContact) => {
    if (form.getFieldValue('bobContactId') !== contact.id) {
      setSelectedContact(contact);
      form.setFieldValue('bobContactId', contact.id);

      await new Promise((resolve) => setTimeout(resolve, 0));

      const result = await refetch();

      setParamsForContact(result.data);
    }
  };

  const hasDisabledFormSubmit = (hasAmount: boolean) => {
    if (!hasBobReferenceId) {
      return true;
    }

    if (
      form.getFieldValue('currency') !== DEFAULT_CURRENCY_CODE &&
      !currencyExchangeRate
    ) {
      return true;
    }

    if (!form.getFieldValue('items').length) return true;

    if (!hasAmount) return true;

    if (!submittable) return true;

    return false;
  };

  return (
    <Spin
      tip="We are uploading the data, please check it as soon as it is uploaded"
      size="large"
      spinning={isLoadingParamsForContact}
    >
      {hasBookingError && (
        <S.ErrorMessage>
          There was an error during document booking:
          <br />
          "{document.adminNotes}".
          <br />
          <br />
          Check the filled data carefully and try again. 
          <br />
          <br />
          If the problem still persists:
          <br />
          1. Book the document manually in BOB50.
          <br />
          2. Mark it as booked in the documents list.
          <br />
          3. Add the 'Doc Id' based on BOB50 document number to this document in the documents list.
        </S.ErrorMessage>
      )}
      
      {!hasBobReferenceId && (
        <S.ErrorMessage>
          Automatic booking is disabled for this company.
          <br />
          <br />
          Please follow these steps:
          <br />
          1. Book the document manually in BOB50.
          <br />
          2. Mark it as booked in the documents list.
          <br />
          3. Add the 'Doc Id' to this document based on BOB50 document number.
        </S.ErrorMessage>
      )}
      <Form
        form={form}
        onFinish={handleSubmit}
        onValuesChange={(_, values) => setFormValues(values)}
        layout="vertical"
        requiredMark={false}
        initialValues={{
          items: [],
        }}
      >
        <Row gutter={[12, 0]}>
          <DocumentExpenseWrapperFormControl
            type="input"
            span={12}
            form={{
              label: 'Number',
              name: 'number',
              rules: [{ required: true }],
            }}
            control={{
              disabled: hasDisabled,
              onChange: (e) =>
                handleUpdateWithDebounce({
                  number: e.target.value,
                }),
            }}
          />

          <DocumentExpenseWrapperFormControl
            type="date-picker"
            span={12}
            form={{
              label: 'Issue date',
              name: 'issueDate',
              rules: [{ required: true }],
            }}
            control={{
              format: DEFAULT_DATE_FORMAT,
              disabled: hasDisabled,
              onChange: (value) => {
                handleUpdate({
                  issueDate: value && dayjs(value).format('YYYY-MM-DD'),
                });
              },
            }}
          />

          <DocumentExpenseWrapperFormControl
            type="input"
            span={12}
            form={{
              label: 'Doc ID',
              name: 'referenceId',
            }}
            control={{
              addonAfter:
                hasDisabled || unlockedDocumentId ? (
                  <Button
                    type="link"
                    icon={<IconLock width={20} height={20} />}
                    onClick={() =>
                      setUnlockedDocumentId((prevState) => !prevState)
                    }
                  />
                ) : null,
              disabled: !isReady ? !unlockedDocumentId && hasDisabled : true,
              onChange: (e) =>
                handleUpdateWithDebounce({
                  referenceId: e.target.value,
                }),
            }}
          />

          <DocumentExpenseWrapperFormControl
            type="date-picker"
            span={12}
            form={{
              label: 'Due date',
              name: 'dueDate',
            }}
            control={{
              format: DEFAULT_DATE_FORMAT,
              disabled: hasDisabled,
              onChange: (value) =>
                handleUpdate({
                  dueDate: value && dayjs(value).format('YYYY-MM-DD'),
                }),
            }}
          />
        </Row>

        <ContactsPopupList
          placement="bottomLeft"
          onSelect={handleUpdateContact}
          onAfterCreate={handleUpdateContact}
          emptyText="Contact not found. Add the contact to BOB50, wait 1 minute and reload the page. It will appear in the list."
          hideCreateAction
          {...(hasDisabled && { open: false })}
        >
          <Form.Item
            label={
              <Flex gap={4} vertical>
                <span>Contact</span>
                <S.ExtraText>
                  Select a contact and the information will be pulled from BOB50
                </S.ExtraText>
              </Flex>
            }
            name="bobContactId"
            rules={[{ required: true }]}
          >
            <S.SelectContacts
              size="large"
              options={[
                {
                  label: selectedContact?.name,
                  value: selectedContact?.id,
                },
              ]}
              disabled={hasDisabled}
              popupClassName="popup-hidden"
            />
          </Form.Item>
        </ContactsPopupList>

        <Row gutter={[12, 0]}>
          <DocumentExpenseWrapperFormControl
            type="select"
            span={8}
            form={{
              label: 'Journal',
              name: 'daybookId',
              rules: [{ required: true }],
            }}
            control={{
              options: (daybooks || []).map((book) => ({
                label: `${book.id} - ${book.name}`,
                value: book.id,
              })),
              loading: isLoadingDaybooks,
              disabled: hasDisabled,
              onChange: (value) =>
                handleUpdate({
                  daybookId: value,
                }),
            }}
          />

          <DocumentExpenseWrapperFormControl
            type="input-number"
            span={8}
            form={{
              label: 'Amount',
              name: 'amount',
              rules: [{ required: true }],
            }}
            control={{
              min: 0,
              disabled: hasDisabled,
              onChange: (value) =>
                handleUpdateWithDebounce({
                  amount: value,
                }),
            }}
          />

          <DocumentExpenseWrapperFormControl
            type="select"
            span={8}
            form={{
              label: 'Currency',
              name: 'currency',
              rules: [{ required: true }],
            }}
            control={{
              options: currencyCodes.map((code) => ({
                label: code,
                value: code,
              })),
              disabled: hasDisabled,
              onChange: (value) =>
                handleUpdate({
                  currency: value,
                }),
            }}
          />
        </Row>

        <Flex gap={12} vertical>
          <Form.List name="items">
            {(items, { add, remove }) => (
              <>
                <S.LinesTitle>Lines</S.LinesTitle>

                {items.map(({ key, name, ...rest }) => (
                  <S.Card key={key} gap={6} vertical>
                    <Flex align="flex-start" gap={8} justify="space-between">
                      <DocumentExpenseWrapperFormControl
                        type="textarea"
                        form={{
                          name: [name, 'name'],
                          rules: [
                            { required: true, message: '' },
                            {
                              validator: (_, value) =>
                                value && value.length > 50
                                  ? Promise.reject(
                                      new Error(
                                        'The number of characters entered has exceeded 50 characters.'
                                      )
                                    )
                                  : Promise.resolve(),
                            },
                          ],
                        }}
                        control={{
                          rows: 3,
                          showCount: true,
                          disabled: hasDisabled,
                          onChange: (e) =>
                            handleUpdateWithDebounce({
                              items: handleUpdateList(
                                'name',
                                name,
                                e.target.value
                              ),
                            }),
                        }}
                      />

                      <Button
                        type="link"
                        icon={<IconTrash width={24} height={24} />}
                        onClick={() => {
                          handleUpdate({
                            items: (formValues?.items || []).filter(
                              (_, index) => name !== index
                            ),
                          });

                          remove(name);
                        }}
                        disabled={hasDisabled}
                        danger
                      />
                    </Flex>

                    <Row gutter={[12, 0]}>
                      <DocumentExpenseWrapperFormControl
                        type="select"
                        span={8}
                        form={{
                          name: [name, 'accountCode'],
                          rules: [{ required: true }],
                          noStyle: true,
                          ...rest,
                        }}
                        control={{
                          placeholder: 'Account code',
                          size: 'middle',
                          options: (accounts || []).map((account) => ({
                            label: `${account.id} - ${account.name}`,
                            value: account.id,
                          })),
                          disabled: hasDisabled,
                          loading: isLoadingAccounts,
                          onChange: (value) =>
                            handleUpdate({
                              items: handleUpdateList(
                                'accountCode',
                                name,
                                value
                              ),
                            }),
                          showSearch: true,
                          optionFilterProp: 'label',
                        }}
                      />

                      <DocumentExpenseWrapperFormControl
                        type="input-number"
                        span={8}
                        form={{
                          name: [name, 'totalPrice'],
                          rules: [{ required: true }],
                          noStyle: true,
                          ...rest,
                        }}
                        control={{
                          min: 0,
                          size: 'middle',
                          disabled: hasDisabled,
                          onChange: (value) =>
                            handleUpdateWithDebounce({
                              items: handleUpdateList(
                                'totalPrice',
                                name,
                                Number(value)
                              ),
                            }),
                        }}
                      />

                      <DocumentExpenseWrapperFormControl
                        type="select"
                        span={8}
                        form={{
                          name: [name, 'vatCode'],
                          rules: [{ required: true }],
                          noStyle: true,
                          ...rest,
                        }}
                        control={{
                          placeholder: 'VAT code',
                          size: 'middle',
                          options: (vats || []).map((vat) => ({
                            label: `${vat.id} - ${vat.name}`,
                            value: vat.id,
                          })),
                          disabled: hasDisabled,
                          loading: isLoadingVats,
                          showSearch: true,
                          optionFilterProp: 'label',
                          onChange: (value) =>
                            handleUpdate({
                              items: handleUpdateList('vatCode', name, value),
                            }),
                        }}
                      />
                    </Row>
                  </S.Card>
                ))}

                <S.LinesAdd
                  disabled={hasDisabled}
                  onClick={() => {
                    handleUpdate({
                      items: [
                        ...(formValues?.items || []),
                        { name: '' } as Schemas.BookingItem,
                      ],
                    });

                    add();
                  }}
                >
                  + Add line
                </S.LinesAdd>
              </>
            )}
          </Form.List>
        </Flex>

        <S.Submit
          align="center"
          justify={formValues?.amount ? 'space-between' : 'flex-end'}
        >
          <Flex gap={24} justify="space-between" flex="1">
            <Flex gap={16} vertical>
              {formValues?.amount && form.getFieldValue('items')?.length ? (
                <DocumentExpenseAmountStatus
                  isEqual={getStatusByAmount(formValues)}
                  label={
                    getStatusByAmount(formValues)
                      ? 'Amounts match'
                      : 'Amounts do not match'
                  }
                />
              ) : !form.getFieldValue('items')?.length ? (
                <DocumentExpenseAmountStatus
                  isEqual={false}
                  label="No added lines"
                />
              ) : (
                <div />
              )}

              {form.getFieldValue('currency') &&
                form.getFieldValue('currency') !== DEFAULT_CURRENCY_CODE &&
                !isLoadingCurrencyExchangeRate &&
                form.getFieldValue('issueDate') && (
                  <DocumentExpenseAmountStatus
                    isEqual={!!currencyExchangeRate}
                    label={
                      currencyExchangeRate
                        ? `Exchange rate: ${currencyExchangeRate} ${form.getFieldValue('currency')}`
                        : 'The exchange rate was not calculated, if the invoice date is today, check the document tomorrow; if the invoice date is not today, write to the support team (#accounting in Slack)'
                    }
                  />
                )}
            </Flex>

            {!isBooked && !isProcessing && hasBobReferenceId && (
              <Form.Item noStyle>
                <Flex justify="flex-end">
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    disabled={hasDisabledFormSubmit(
                      getStatusByAmount(formValues)
                    )}
                  >
                    Submit for booking
                  </Button>
                </Flex>
              </Form.Item>
            )}
          </Flex>
        </S.Submit>
      </Form>
    </Spin>
  );
};

export default DocumentExpenseForm;
