import { Space } from 'antd';
import { PropsWithChildren, ReactNode } from 'react';

import { GoBack } from '@entities';

import * as S from './styled';

type DetailsHeaderProps = {
  goBackText?: ReactNode;
  onNavigate?: VoidFunction;
};

const DetailsHeader = ({
  children,
  goBackText,
  onNavigate,
}: PropsWithChildren<DetailsHeaderProps>) => (
  <S.Header>
    <Space split={<S.Divider />}>
      <GoBack text={goBackText} onNavigate={onNavigate} />
      {children}
    </Space>
  </S.Header>
);

export default DetailsHeader;
