import { Flex } from 'antd';

import { StatusCircle } from '@entities';

import * as S from './styled';

type DocumentExpenseAmountStatusProps = {
  isEqual: boolean;
  label: string;
};

const DocumentExpenseAmountStatus = ({
  isEqual,
  label,
}: DocumentExpenseAmountStatusProps) => (
  <Flex gap={8}>
    <StatusCircle status={isEqual ? 'completed' : 'errorsFound'} />
    <S.Status>{label}</S.Status>
  </Flex>
);

export default DocumentExpenseAmountStatus;
