import {
  Button,
  Col,
  Collapse,
  CollapseProps,
  Flex,
  List,
  Modal,
  Row,
  Table,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import type { PartialDeep } from 'type-fest';

import { useAdminIncorporationController_findOneById } from '@api-client/generated/AdminIncorporationController/findOneById';
import { useAdminIncorporationController_updateOneById } from '@api-client/generated/AdminIncorporationController/updateOneById';
import { Schemas } from '@api-client/generated/types';
import { InfoCell, Loader } from '@components';
import {
  CompanyDetailsShareholderEdit,
  CompanyOnboardingDetailsEdit,
  CompanyOnboardingInfoEdit,
  IncorporationKYC,
  PersonFileModal,
} from '@entities';
import { useCurrentCompanyId } from '@hooks';

import { columns } from './config';
import * as S from './styled';
import { deduplicatePeople, flattenDeep } from './utils';

type Person = Schemas.Person;
type Incorporation = PartialDeep<Schemas.Incorporation>;
type IncorporationDto = PartialDeep<Schemas.AdminIncorporationDto>;

type IncorporationWithDto = Incorporation | IncorporationDto;

const { Text } = Typography;

type Shareholder = {
  type: string;
  name: string;
  shares: number;
  isDirector: boolean;
  isSigner: boolean;
};

type CompanyDetails = {
  companyNames: string[];
  companyType: string;
  companyActivity: string;
};

const PLANS = {
  1: '0-€75K',
  2: '€75K-€150K',
  3: '€150K-€300K',
  4: '€300K-€500K',
  5: '€500K-€1000K',
  6: '€1000K-€2000K',
  7: '€2000K+',
};

const CompanyDetailsIncorporation = () => {
  const companyId = useCurrentCompanyId();

  const [modal, contextHolder] = Modal.useModal();

  const [incorporation, setIncorporation] =
    useState<IncorporationWithDto | null>(null);
  const [activeFile, setActiveFile] = useState<Schemas.CompanyFile | null>(
    null
  );
  const [isShareholderEditModalOpen, setIsShareholderEditModalOpen] =
    useState(false);
  const [isDetailsEditModalOpen, setIsDetailsEditModalOpen] = useState(false);
  const [isInfoEditModalOpen, setIsInfoEditModalOpen] = useState(false);

  const {
    data: incorporationData,
    isPending,
    refetch,
  } = useAdminIncorporationController_findOneById({
    params: {
      companyId,
    },
  });
  const { mutate: updateIncorporationById } =
    useAdminIncorporationController_updateOneById();

  useEffect(() => {
    if (incorporationData) {
      setIncorporation(incorporationData);
    }
  }, [incorporationData]);

  const updateIncorporation = () => {
    refetch();
  };

  const updateCompanyDetails = (values: CompanyDetails) => {
    updateIncorporationById(
      {
        parameter: {
          companyId: companyId!,
        },
        // @ts-expect-error-next-line
        requestBody: {
          ...values,
        },
      },
      {
        onSuccess: () => {
          updateIncorporation();
        },
      }
    );
  };

  const updateCompanyInfo = (values: Schemas.KYCData) => {
    if (companyItself) {
      companyItself.kycData = {
        ...companyItself.kycData,
        ...values,
      };
    }

    updateIncorporationById(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: incorporation?.people,
        },
      },
      {
        onSuccess: () => {
          updateIncorporation();
        },
      }
    );
  };

  const handleAddShareholder = (value: Shareholder) => {
    const companyPerson = incorporation?.people?.find(
      (person) => person.isCompanyItself
    );
    const companyPeople = companyPerson?.people || [];

    if (!companyPerson) {
      return;
    }

    updateIncorporationById(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: [
            {
              ...companyPerson,
              people: [
                // @ts-expect-error-next-line
                ...companyPeople,
                // @ts-expect-error-next-line
                {
                  type: value.type,
                  name: value.name,
                  shares: Number(value.shares),
                  isDirector: value.isDirector,
                  isSigner: value.isSigner,
                  isShareholder: true,
                  people: [],
                },
              ],
            },
          ],
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const kycStatus = (incorporation: IncorporationWithDto) => {
    if (incorporation.isKycFailed) {
      return 'Failed';
    }

    if (incorporation.isCheckComplete) {
      return 'Passed';
    }

    // @ts-expect-error-next-line
    if (incorporation.isSubmittedToKyc) {
      return 'Submitted';
    }

    return 'Pending';
  };

  const people = deduplicatePeople(
    flattenDeep((incorporation?.people || []) as Person[])
  );

  const companyItself = people.find((person) => person.isCompanyItself);

  const getStatusDescription = (file: Schemas.CompanyFile) => {
    if (file.isApproved) {
      return 'Approved';
    }

    if (file.hasError) {
      return 'Has error, waiting for client';
    }

    return 'Needs to be checked';
  };

  const deleteShareholder = (id: string) => {
    const companyPerson = incorporation?.people?.find(
      (person) => person.isCompanyItself
    );
    const companyPeople = companyPerson?.people || [];

    if (!companyPerson) {
      return;
    }

    updateIncorporationById(
      {
        parameter: {
          companyId: companyId!,
        },
        requestBody: {
          people: [
            {
              ...companyPerson,
              people: companyPeople.filter((person) => person.id !== id),
            },
          ],
        },
      },
      {
        onSuccess: () => {
          refetch();
        },
      }
    );
  };

  const handleDeleteShareholderClick = (id: string) => {
    modal.confirm({
      title: 'Delete shareholder',
      content: 'Are you sure you want to delete this shareholder?',
      onOk: () => {
        deleteShareholder(id);
      },
    });
  };

  const handleEditCompanyDetailsClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setIsDetailsEditModalOpen(true);
  };

  const handleEditCompanyInfoClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setIsInfoEditModalOpen(true);
  };

  const getSelectedPlan = () =>
    incorporation &&
    'accountingPlanId' in incorporation &&
    incorporation.accountingPlanId
      ? PLANS[incorporation.accountingPlanId as keyof typeof PLANS]
      : '-';

  const renderCompanyFiles = (section: string) => {
    const files = companyItself?.files?.[section];

    if (!files) {
      return null;
    }

    return (
      <Flex gap={8} vertical>
        {files.map((file) => (
          <>
            <Flex key={file.id} gap={8}>
              <Button
                onClick={() => {
                  setActiveFile(file);
                }}
                type="link"
              >
                View
              </Button>
            </Flex>
            {getStatusDescription(file)}
            {file.events.length ? (
              <List
                dataSource={file.events}
                renderItem={(event) => (
                  <List.Item>
                    {event.type.toLocaleUpperCase()} by{' '}
                    {[event.user.firstName, event.user.lastName].join(' ')} on{' '}
                    {dayjs(event.createdAt).format('DD.MM.YYYY HH:mm')}
                  </List.Item>
                )}
              />
            ) : null}
          </>
        ))}
      </Flex>
    );
  };

  const sections: CollapseProps['items'] = useMemo(
    () =>
      incorporation
        ? [
            {
              key: 'details',
              label: (
                <Flex justify="space-between">
                  <Text strong>Company details</Text>
                  <Button
                    size="small"
                    type="text"
                    onClick={handleEditCompanyDetailsClick}
                  >
                    Edit
                  </Button>
                </Flex>
              ),
              children: (
                <Row gutter={[60, 30]}>
                  <Col span={6}>
                    <InfoCell label="Stage" value={incorporation.group} />
                  </Col>
                  <Col span={6}>
                    <InfoCell
                      label="Name(s)"
                      // @ts-expect-error-next-line
                      value={incorporation.companyNames.join(', ')}
                    />
                  </Col>

                  <Col span={6}>
                    <InfoCell
                      label="Type" // @ts-expect-error-next-line
                      value={incorporation.companyType}
                    />
                  </Col>

                  <Col span={6}>
                    <InfoCell
                      label="Incorporation type" // @ts-expect-error-next-line
                      value={incorporation.incorporationType}
                    />
                  </Col>

                  <Col span={6}>
                    <InfoCell
                      label="KYC status"
                      value={kycStatus(incorporation)}
                    />
                  </Col>

                  <Col span={24}>
                    <InfoCell
                      label="Company activity"
                      // @ts-expect-error-next-line
                      value={incorporation.companyActivity}
                    />
                  </Col>
                  <Col span={24}>
                    <InfoCell label="Selected plan" value={getSelectedPlan()} />
                  </Col>
                </Row>
              ),
            },
            {
              key: 'info',
              label: (
                <Flex justify="space-between">
                  <Text strong>Company info</Text>
                  <Button
                    size="small"
                    type="text"
                    onClick={handleEditCompanyInfoClick}
                  >
                    Edit
                  </Button>
                </Flex>
              ),
              children: (
                <>
                  <Row gutter={[60, 30]}>
                    <Col span={12}>
                      <InfoCell
                        label="Purpose of istablishment"
                        value={companyItself?.kycData?.purposeOfEstablishment}
                      />
                    </Col>
                    <Col span={12}>
                      <InfoCell
                        label="Assessment of incoming funds"
                        value={
                          companyItself?.kycData?.assessmentOfIncomingFunds
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <InfoCell
                        label="Assessment of outgoing funds"
                        value={
                          companyItself?.kycData?.assessmentOfOutgoingFunds
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <InfoCell
                        label="Clients of company"
                        value={companyItself?.kycData?.clientsOfCompany}
                      />
                    </Col>
                    <Col span={12}>
                      <InfoCell
                        label="Countries involved"
                        value={companyItself?.kycData?.countriesInvolved}
                      />
                    </Col>
                    <Col span={12}>
                      <InfoCell
                        label="Long term goals"
                        value={companyItself?.kycData?.longTermGoals}
                      />
                    </Col>
                  </Row>
                  <S.CompanyInfoDocuments vertical>
                    <Typography.Title level={5}>Documents</Typography.Title>
                    <S.CompanyInfoDocumentsBody>
                      <Text>Extract from commercial register</Text>
                      <Flex gap={8} vertical>
                        {renderCompanyFiles('extract')}
                      </Flex>

                      <Text>Articles of Association (AoA) or deed</Text>
                      <Flex gap={8} vertical>
                        {renderCompanyFiles('aoa')}
                      </Flex>

                      <Text>Last annual accounts</Text>
                      <Flex gap={8} vertical>
                        {renderCompanyFiles('annual_accounts')}
                      </Flex>

                      <Text>Chart with ownership and %</Text>
                      <Flex gap={8} vertical>
                        {renderCompanyFiles('ownership_chart')}
                      </Flex>
                    </S.CompanyInfoDocumentsBody>
                  </S.CompanyInfoDocuments>
                </>
              ),
            },
            {
              key: 'structure',
              label: <Text strong>Company Structure</Text>,
              children: (
                <Row gutter={[48, 30]}>
                  <Col span={24}>
                    <Flex gap={16} vertical>
                      <Table
                        rowKey={({ id }) => id}
                        dataSource={
                          people.filter((person) => person.isShareholder) || []
                        }
                        columns={columns({
                          onDelete: handleDeleteShareholderClick,
                        })}
                        loading={false}
                        pagination={false}
                        scroll={{ x: 720 }}
                      />
                      <Flex>
                        <Button
                          type="text"
                          onClick={() => setIsShareholderEditModalOpen(true)}
                        >
                          Add shareholder
                        </Button>
                      </Flex>
                    </Flex>
                  </Col>
                </Row>
              ),
            },
            {
              key: 'owners',
              label: <Text strong>Owners</Text>,
              children: (
                <IncorporationKYC
                  incorporation={incorporation}
                  people={people}
                  onUpdate={updateIncorporation}
                />
              ),
            },
          ]
        : [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [incorporation, companyItself, people, updateIncorporation, handleDeleteShareholderClick]
  );

  if (isPending || !incorporation) {
    return <Loader />;
  }

  return (
    <S.Root>
      <Collapse
        items={sections}
        defaultActiveKey={['details', 'info', 'structure', 'owners']}
      />
      <CompanyDetailsShareholderEdit
        open={isShareholderEditModalOpen}
        totalShares={people
          .filter((person) => person.isShareholder)
          .reduce((acc, person) => Number(acc) + person.shares, 0)}
        onCancel={() => setIsShareholderEditModalOpen(false)}
        onSubmit={handleAddShareholder}
      />
      {activeFile && (
        <PersonFileModal
          open={!!activeFile}
          file={activeFile!}
          title="File"
          onCancel={() => setActiveFile(null)}
        />
      )}
      {incorporation && (
        <CompanyOnboardingDetailsEdit
          isOpen={isDetailsEditModalOpen}
          company={{
            // @ts-expect-error-next-line
            companyNames: incorporation.companyNames,
            // @ts-expect-error-next-line
            companyType: incorporation.companyType,
            // @ts-expect-error-next-line
            companyActivity: incorporation.companyActivity,
          }}
          onSubmit={updateCompanyDetails}
          onCancel={() => setIsDetailsEditModalOpen(false)}
        />
      )}
      {incorporation && (
        <CompanyOnboardingInfoEdit
          isOpen={isInfoEditModalOpen}
          companyKYC={companyItself?.kycData}
          onSubmit={updateCompanyInfo}
          onCancel={() => setIsInfoEditModalOpen(false)}
        />
      )}
      {contextHolder}
    </S.Root>
  );
};

export default CompanyDetailsIncorporation;
