//
// Generated by @himenon/openapi-typescript-code-generator v0.27.4
//
// OpenApi : 3.0.0
//
//
import {
  Params$AdminCurrencyExchangeRateController_getRate,
  Response$AdminCurrencyExchangeRateController_getRate$Status$200,
  ResponseContentType$AdminCurrencyExchangeRateController_getRate,
} from '@api-client/generated/client';
import { QueryOptions, useApiClient } from '@hooks/useApiClient';

export const useAdminCurrencyExchangeRateController_getRate = (options: {
  params: Params$AdminCurrencyExchangeRateController_getRate['parameter'];
  config?: QueryOptions<
    Response$AdminCurrencyExchangeRateController_getRate$Status$200[ResponseContentType$AdminCurrencyExchangeRateController_getRate]
  >;
}) =>
  useApiClient<
    Params$AdminCurrencyExchangeRateController_getRate,
    Response$AdminCurrencyExchangeRateController_getRate$Status$200[ResponseContentType$AdminCurrencyExchangeRateController_getRate]
  >({
    method: 'AdminCurrencyExchangeRateController_getRate',
    params: { parameter: options.params },
    config: options.config,
  });
