import { Flex, Popover, Tabs, Typography } from 'antd';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useAdminBillingDocumentController_findAllOneTimeInvoices } from '@api-client/generated/AdminBillingDocumentController/findAllOneTimeInvoices';
import { useAdminBillingDocumentController_findOneById } from '@api-client/generated/AdminBillingDocumentController/findOneById';
import { useAdminBillingPeriodController_findAll } from '@api-client/generated/AdminBillingPeriodController/findAll';
import { useAdminBillingPeriodController_findOneById } from '@api-client/generated/AdminBillingPeriodController/findOneById';
import { Schemas } from '@api-client/generated/types';
import { IconPlus } from '@assets';
import { Scrollbar } from '@components';
import {
  BillingList,
  BillingOneTimeInvoiceCard,
  BillingPeriodCard,
  ModalBillingAction,
  ModalBillingActionPeriod,
  ModalBillingOneTimeInvoice,
} from '@entities';
import { useCurrentCompany, useCurrentCompanyId } from '@hooks';

import * as S from './styled';

type BillingPeriod = Schemas.BillingPeriod;
type BillingDocument = Schemas.BillingDocument;
type InvoiceItem = Schemas.InvoiceItem;

type ActionType = 'add' | 'edit';

const Billing = () => {
  const { billingId, oneTimeBillingId } = useParams();
  const companyId = useCurrentCompanyId();
  const { data: company } = useCurrentCompany();

  const navigate = useNavigate();

  const [modalActionVisible, setModalActionVisible] = useState(false);
  const [modalOneTimeInvoiceVisible, setModalOneTimeInvoiceVisible] =
    useState(false);
  const [modalActionType, setModalActionType] = useState<ActionType>('add');
  const [selectedInvoiceService, setSelectedInvoiceService] =
    useState<InvoiceItem | null>(null);

  const [modalActionPeriodVisible, setModalActionPeriodVisible] =
    useState(false);
  const [modalActionPeriodType, setModalActionPeriodType] =
    useState<ActionType>('add');

  const [billingPeriodsList, setBillingPeriodsList] = useState<BillingPeriod[]>(
    []
  );
  const [billingOneTimeInvoicesList, setBillingOneTimeInvoicesList] = useState<
    BillingDocument[]
  >([]);

  const { data: periodsList, refetch: refetchBillingPeriods } =
    useAdminBillingPeriodController_findAll({
      params: {
        companyId: companyId!,
      },
    });

  const { data: oneTimeInvoicesList, refetch: refetchBillingOneTimeInvoices } =
    useAdminBillingDocumentController_findAllOneTimeInvoices({
      params: {
        companyId: companyId!,
      },
    });

  useEffect(() => {
    if (periodsList) {
      setBillingPeriodsList(periodsList);
    }
  }, [periodsList]);

  useEffect(() => {
    if (oneTimeInvoicesList) {
      setBillingOneTimeInvoicesList(oneTimeInvoicesList);
    }
  }, [oneTimeInvoicesList]);

  const {
    data: period,
    isFetching: isFetchingBillingPeriod,
    refetch: refetchBillingPeriod,
  } = useAdminBillingPeriodController_findOneById({
    params: {
      companyId: companyId!,
      id: billingId!,
    },
    config: {
      enabled: !!billingId,
      refetchOnWindowFocus: false,
    },
  });

  const { data: oneTimeInvoice, isFetching: isFetchingBillingOneTimeInvoice } =
    useAdminBillingDocumentController_findOneById({
      params: {
        companyId: companyId!,
        id: oneTimeBillingId!,
      },
      config: {
        enabled: !!oneTimeBillingId,
        refetchOnWindowFocus: false,
      },
    });

  const checkCompany = useCallback(
    () =>
      !!(
        company?.name &&
        company?.details?.address?.houseNumber &&
        company?.details?.address?.street &&
        company?.details?.address?.postalCode &&
        company?.details?.address?.city
      ),
    [company]
  );

  const tabs = useMemo(
    () => [
      {
        key: 'monthly',
        label: 'Monthly invoices',
        children: (
          <Scrollbar height="calc(100vh - 272px)">
            <BillingList
              type="period"
              dataSource={billingPeriodsList}
              onAdd={() => handleOpenPeriodModal('add')}
            />
          </Scrollbar>
        ),
      },
      {
        key: 'one-time',
        label: 'One-time invoices',
        children: (
          <Scrollbar height="calc(100vh - 272px)">
            <BillingList
              type="one-time"
              isDisabled={!checkCompany()}
              dataSource={billingOneTimeInvoicesList}
              onAdd={() => handleOpenOneTimeInvoiceModal()}
            />
          </Scrollbar>
        ),
      },
    ],
    [billingPeriodsList, billingOneTimeInvoicesList, checkCompany]
  );

  const handleOpenModal = (
    type: ActionType,
    invoice: InvoiceItem | null = null
  ) => {
    setModalActionVisible(true);
    setModalActionType(type);

    if (invoice) {
      setSelectedInvoiceService(invoice);
    }
  };

  const handleCloseModal = () => {
    setModalActionVisible(false);
    setModalActionType('add');
    setSelectedInvoiceService(null);
  };

  const handleOpenPeriodModal = (type: ActionType) => {
    setModalActionPeriodVisible(true);
    setModalActionPeriodType(type);
  };

  const handleClosePeriodModal = () => {
    setModalActionPeriodVisible(false);
    setModalActionPeriodType('add');
  };

  const handleOpenOneTimeInvoiceModal = () => {
    setModalOneTimeInvoiceVisible(true);
  };

  const handleAfterUpdateDocument = (id?: string) => {
    setBillingPeriodsList((prevState) =>
      prevState.map((period) =>
        period.id === billingId
          ? ({
              ...period,
              billingDocument: id ? { id } : null,
            } as BillingPeriod)
          : period
      )
    );
  };

  const handleAfterDeleteOneTimeInvoice = () => {
    navigate(`/companies/${companyId}/billing`);
    refetchBillingOneTimeInvoices();
  };

  return (
    <Flex gap={24} vertical>
      <ModalBillingAction
        details={period}
        selected={selectedInvoiceService}
        type={modalActionType}
        open={modalActionVisible}
        onAfterUpdate={() => refetchBillingPeriod()}
        onCancel={handleCloseModal}
      />

      <ModalBillingActionPeriod
        details={period}
        type={modalActionPeriodType}
        open={modalActionPeriodVisible}
        onAfterCreate={refetchBillingPeriods}
        onAfterUpdate={() => refetchBillingPeriods()}
        onCancel={handleClosePeriodModal}
      />

      <ModalBillingOneTimeInvoice
        isOpen={modalOneTimeInvoiceVisible}
        onAfterCreate={refetchBillingOneTimeInvoices}
        onCancel={() => setModalOneTimeInvoiceVisible(false)}
      />

      <Flex align="flex-start" gap={30}>
        <S.Inner>
          <S.Header align="center" justify="space-between">
            <S.Title>Billing</S.Title>

            <Flex gap={4} vertical>
              <S.AddButton
                icon={<IconPlus width={24} />}
                type="text"
                onClick={() => handleOpenPeriodModal('add')}
              >
                Add period
              </S.AddButton>
              <Popover
                content={
                  !checkCompany() && (
                    <>
                      <Typography.Paragraph>
                        Fill the following fields on Company Info tab:
                      </Typography.Paragraph>
                      <ul>
                        <li>Company name</li>
                        <li>Address</li>
                      </ul>
                    </>
                  )
                }
              >
                <S.AddButton
                  icon={<IconPlus width={24} />}
                  type="text"
                  disabled={!checkCompany()}
                  onClick={() => handleOpenOneTimeInvoiceModal()}
                >
                  Add one-time invoice
                </S.AddButton>
              </Popover>
            </Flex>
          </S.Header>

          <Tabs items={tabs} />
        </S.Inner>

        <S.Details>
          {period && (
            <BillingPeriodCard
              details={period}
              isLoading={isFetchingBillingPeriod}
              onAdd={() => handleOpenModal('add')}
              onEdit={() => handleOpenPeriodModal('edit')}
              onEditService={(invoice) => handleOpenModal('edit', invoice)}
              onAfterDelete={() => refetchBillingPeriods()}
              onAfterCreateDocument={handleAfterUpdateDocument}
              onAfterDeleteDocument={handleAfterUpdateDocument}
            />
          )}
          {oneTimeInvoice && (
            <BillingOneTimeInvoiceCard
              details={oneTimeInvoice}
              isLoading={isFetchingBillingOneTimeInvoice}
              onAfterDeleteDocument={handleAfterDeleteOneTimeInvoice}
            />
          )}
        </S.Details>
      </Flex>
    </Flex>
  );
};

export default Billing;
